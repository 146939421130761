import classNames from "classnames";
import React, { SyntheticEvent } from "react";

import useFileInput from "../hook/file-handler";

type InputDefultProps = {
  type?: string;
  name?: string;
  autoComplete?: string;
  required?: boolean;
  requiredByIndicator?: boolean;
  classNameInput?: string[] | string;
  placeholder?: string;
  defaultChecked?: boolean;
  defaultValue?: any;
  htmlFor?: string;
  classNameLabel?: string[] | string;
  labelTitle?: string;
  classNameGrouped?: string;
  onChange?: any;
  shortDescriptionOnFile?: string;
  extraTagFile?: string;
  numberOfCols?: number;
  numberOfRows?: number;
};
export const InputBox = ({
  type = "text",
  name = "",
  autoComplete = "",
  required = false,
  classNameInput,
  placeholder = "",
  defaultValue = "",
  htmlFor = "",
  classNameLabel = "sr-only",
  labelTitle = "",
  classNameGrouped = "",
  onChange,
  requiredByIndicator,
}: InputDefultProps) => {
  const styliInput =
    typeof classNameInput === "object"
      ? (classNameInput.join(" ") as string)
      : classNameInput;

  const styliLabel =
    typeof classNameLabel === "object"
      ? (classNameLabel.join(" ") as string)
      : classNameLabel;

  let label = typeof name === "string" ? name.replace(/(\s)/gi, "-") : "",
    labelTitleInput =
      typeof labelTitle === "string"
        ? labelTitle ?? name.replace(/(\_)/gi, " ")
        : "";
  return (
    <div className={classNameGrouped}>
      <label htmlFor={htmlFor ?? label} className={styliLabel}>
        {labelTitleInput}
        {requiredByIndicator && <span className="text-red-500">*</span>}
      </label>
      <input
        id={htmlFor ?? label}
        name={name}
        type={type}
        autoComplete={autoComplete ?? name}
        required={required}
        className={styliInput}
        placeholder={placeholder}
        defaultValue={defaultValue}
        onChange={onChange}
      />
    </div>
  );
};

export const TextAreaBox = ({
  type = "text",
  name = "",
  autoComplete = "",
  required = false,
  classNameInput,
  placeholder = "",
  defaultValue = "",
  htmlFor = "",
  classNameLabel = "sr-only",
  labelTitle = "",
  classNameGrouped = "",
  onChange,
  numberOfCols,
  numberOfRows,
}: InputDefultProps) => {
  const styliInput =
    typeof classNameInput === "object"
      ? (classNameInput.join(" ") as string)
      : classNameInput;

  const styliLabel =
    typeof classNameLabel === "object"
      ? (classNameLabel.join(" ") as string)
      : classNameLabel;

  let label = name.replace(/\s/gi, "-");
  return (
    <div className={classNameGrouped}>
      <label htmlFor={htmlFor ?? label} className={styliLabel}>
        {labelTitle}
      </label>
      <textarea
        id={htmlFor ?? label}
        name={name}
        autoComplete={autoComplete ?? name}
        required={required}
        className={styliInput}
        placeholder={placeholder}
        defaultValue={defaultValue}
        onChange={onChange}
        cols={numberOfCols}
        rows={numberOfRows}
      ></textarea>
    </div>
  );
};

type FileProps = {
  fileType: "images" | "docx";
  onSubmit: any;
  onSelect: any;
  shortDescriptionOnFile?: string;
  extraTagFile?: string;
  labelTitle?: string;
  layout?: string;
};
export const FileBox = ({
  labelTitle,
  shortDescriptionOnFile = "PNG, JPG up to 10MB",
  extraTagFile = "Upload a file",
  fileType,
  onSubmit,
  onSelect,
  layout,
}: FileProps) => {
  const [files, setFiles] = React.useState<any[]>([]);
  return (
    <form>
      <div>
        <label className="block text-sm font-medium text-gray-700">
          {labelTitle}
        </label>
        <div
          className={classNames(
            "mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md",
            layout
          )}
        >
          {fileType !== "docx" && (
            <div className="text-black text-[10px] w-full">
              {
                "Please ensure 4 images of possibly different sides of the product should be uploaded"
              }
            </div>
          )}
          <div className="space-y-1 text-center">
            <svg
              className="mx-auto h-12 w-12 text-gray-400"
              stroke="currentColor"
              fill="none"
              viewBox="0 0 48 48"
              aria-hidden="true"
            >
              <path
                d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <div className="flex text-sm text-gray-600">
              <label
                htmlFor="file-upload"
                className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
              >
                <span className="text-blue-400 underline cursor-pointer">
                  {extraTagFile}
                </span>
                <span className="text-blue-400 underline cursor-pointer pl-1">
                  {"by clicking here"}
                </span>
                {fileType === "docx" ? (
                  // @ts-ignore
                  <input
                    id="file-upload"
                    name="file"
                    type="file"
                    className="sr-only"
                    onChange={(e) => {
                      onSelect(e.target.files);
                      setFiles(e.target.files);
                    }}
                  />
                ) : (
                  // @ts-ignore
                  <input
                    id="file-upload"
                    name="file"
                    type="file"
                    className="sr-only"
                    multiple
                    onChange={(e) => {
                      onSelect(e.target.files);
                      setFiles(e.target.files);
                    }}
                  />
                )}
              </label>
              <p className="pl-1"></p>
            </div>
            <p className="text-xs text-gray-500">{shortDescriptionOnFile}</p>
          </div>
        </div>
      </div>
      {files.length > 0 && (
        <div className="inline-flex justify-center items-center">
          <button
            type="button"
            onClick={() => onSubmit(files)}
            className="font-bold w-fit m-2 inline-block justify-center py-2 px-4 border border-transparent shadow-sm text-sm rounded-md text-bold bg-gray-200 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            <div>{"Save"}</div>
            <div>
              {fileType === "docx"
                ? files.length + " / 1"
                : files.length + " / 4"}
            </div>
          </button>
          <button
            type="button"
            onClick={() => setFiles([])}
            className="font-bold w-3/4 m-2 block justify-center py-2 px-4 border border-transparent shadow-sm text-sm rounded-md text-bold bg-gray-200 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            {"Cancel"}
          </button>
        </div>
      )}
    </form>
  );
};
