import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { exchangesAction, exchangesSelector } from "../../redux/reducers/exchanges-reducer";
import { initialState } from "../../redux/reducers/exchanges-reducer";

type UseExchangesProps = {
    createOrUpdateExchanges:(payload?:any) => any 
    getExchanges:(payload?:any) => any 
    deactivateExchanges:(payload?:any) => any 
    deleteExchanges:(payload?:any) => any

} & typeof initialState

export const useExchanges = ():UseExchangesProps => {
    const dispatch = useDispatch();
    const state = useSelector(exchangesSelector);


    const createOrUpdateExchanges = (payload:any) => dispatch<any>(exchangesAction({path:"/exchanges", payload}));
    const getExchanges = (payload:any) => dispatch<any>(exchangesAction({path:"/exchangess", payload}));
    const deactivateExchanges = (payload:any) => dispatch<any>(exchangesAction({path:"/exchanges/status", payload}));
    const deleteExchanges = (payload:any) => dispatch<any>(exchangesAction({path:"/exchanges/status", payload,}));

    return {
        ...state,
        createOrUpdateExchanges, 
        getExchanges, 
        deactivateExchanges, 
        deleteExchanges
    }
}