import React from "react";

import Modal from "./modal";
import { useModal } from "./modal.context";

const Alert = React.lazy(() => import("../alert-popup"));
const OrderOverview = React.lazy(() => import("../orders/order-overview"));

export function ModalManager() {
  const { closeModal, isOpen, view, payload } = useModal();

  return (
    <Modal open={isOpen} onClose={closeModal}>
      {view === "ALERT" && <Alert item={payload} />}
      {view === "VIEW_ORDER" && <OrderOverview item={payload} />}
    </Modal>
  );
}
