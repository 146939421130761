import React from 'react'
import classNames from 'classnames'

type ActionButtonProps = {
    onClickEditor?:any
    onClickControl?:any
    styliCondition?:any
    editorButtonTitle?:any
    controlButtonTitle?:any
    settings?:{
      editor?:boolean
      control?:boolean
    }
}

function ActionButton({ 
  onClickEditor, 
  onClickControl, 
  styliCondition, 
  editorButtonTitle = 'Add / Edit', 
  controlButtonTitle = 'Control', 
  settings = {
    control:true,
    editor:true
  }
}: ActionButtonProps) {

    return (
      <div className='p-3 bg-white'>
      {settings?.editor && 
      <span onClick={onClickEditor}
        className={classNames(
          'inline-flex justify-center bg-white p-3 transition cursor-pointer ease-in-out mr-5 rounded-md shadow',
          {'bg-gray-200 ': styliCondition === 1}
          )}>
        {editorButtonTitle}
      </span>}
      {settings?.control && 
      <span onClick={onClickControl}
        className={classNames(
          'inline-flex justify-center bg-white p-3 transition cursor-pointer ease-in-out rounded-md shadow',
          {'bg-gray-200 ': styliCondition === 2}
          )}>
        {controlButtonTitle}
      </span>}
  </div>
    )
}

export default ActionButton
