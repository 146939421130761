import classNames from "classnames";
import React, { useState } from "react";

import { formSchema } from "../../constants/form-names";
import { CreateOrUpdateForm } from "../ui/create-or-update.tsx";
import SearchBar from "../ui/search-bar";
import TableLayout from "../ui/table";
import { TagShortDecription } from "../ui/tag-short-decription";
import { useModal } from "../ui/modal/modal.context";
import Loader from "../ui/spinner";
import { usePermission } from "../hook/permission";
import { useSearchEngineFull } from "../hook/search-engine";
import { ErrorPage } from "./error";
import { useCategory } from "../hook/use-category";
import { usePersonal } from "../hook/user-detail";
import { submitter } from "../hook/submitter";
import ControlButton from "../ui/button/control-button";
import ActionButton from "../ui/button/action-button";
import {
  ExtensionFormValuesProps,
  PermissionFormValuesProps,
} from "../../types";

const pageSettings = {
  pageName: "category",
  item: {
    counter: true,
  },
  schema: {
    form: "categoryForm",
    option: "categoryOptionForm",
    formKeys: formSchema
      .filter((v) => v.schema === "categoryForm")
      .map((v) => v.key),
    searchColumns: ["id", "name"],
    searchIndex: 2,
    formExtension: [
      { name: "category", title: "Category" },
      { name: "subcategory", title: "Subcategory" },
      { name: "currency", title: "currency" },
    ],
  },
  explore: {
    isOpen: false,
    editor: 0,
    item: null,
    currentItemOriginalDetails: null,
  },
  control: {
    allowedControl: true,
    restrictions: [],
  },
};

function Categories() {
  const { data } = usePersonal(true);
  const isAdmin = usePermission(2);
  const { openModal } = useModal();
  const [explored, explore] = useState<typeof pageSettings.explore | any>(
    pageSettings.explore
  );
  const [notifier, setNotifier] = useState<boolean>(false);
  const searcher = useSearchEngineFull();
  const categories = useCategory();
  const [extensionFormValues, setextensionFormValues] = React.useState<
    ExtensionFormValuesProps | any
  >({
    address: null,
    category: null,
    subcategory: null,
    brand: null,
    subpscriptionPlanType: null,
    subpscriptionPlanPeriod: null,
    shop: null,
    currency: null,
  });

  //preset calls
  let owner: any = data;
  const information = categories,
    listInformation = searcher.info?.HLL ?? categories.info?.HLL?.data;

  React.useEffect(() => {
    categories.getCategory({ page: 1 });
  }, []);

  //___list
  //select on list
  const handleSelect = (item) => {
    const currentItemOriginalDetails = information.info?.HLL?.data.find(
      (v) => v.id === item.ID
    );
    return explore({
      isOpen: true,
      editor: 1,
      item,
      currentItemOriginalDetails,
    });
  };
  //regenerate list with columns' title
  const listed =
    typeof information.info === "object" &&
    information.info?.HLL?.data.length !== undefined
      ? listInformation.map((item) => {
          // console.log("original", item);
          const selectedDetail = filterInformation(item);
          let currentProperties = Object.entries(selectedDetail),
            columns = currentProperties.map((property) => {
              return {
                title:
                  property[0] === "commission_flat_rate"
                    ? "commission(%)"
                    : property[0] === "commission_real_value"
                    ? "commission(Amount)"
                    : property[0].charAt(0).toUpperCase() +
                      property[0]?.replace(/[(_)(-)]/gi, " ").slice(1),
                dataIndex: property[0],
                key: property[0],
                render: (value, row) => (
                  <div
                    className={classNames(
                      "text-justify mx-5 cursor-pointer text-[15px]"
                    )}
                    onClick={() => handleSelect(row)}
                    onDoubleClick={() =>
                      openModal({ view: "ALERT", payload: value })
                    }
                  >
                    {value}
                  </div>
                ),
              };
            });
          return {
            ...selectedDetail,
            columns,
          };
        })
      : [];

  //searching
  const handleSearch = (searchTerm) => {
    searcher.findRelated({
      keyIndex: pageSettings.schema.searchIndex,
      value: searchTerm,
      targets: pageSettings.schema.searchColumns,
    });
  };

  //create or update
  const handleSubmit = (e) => {
    e.preventDefault();

    let payload = submitter(e, extensionFormValues, explored, owner);

    setNotifier(!notifier);
    information.createOrUpdateCategory({
      ...payload,
      name: extensionFormValues?.category_name,
    });
  };

  React.useEffect(() => {
    if (notifier && information.error) {
      openModal({ view: "ALERT", payload: information.error });
      setTimeout(() => {
        setNotifier(!notifier);
      }, 1500);
    } else if (notifier && information.info?.query) {
      openModal({ view: "ALERT", payload: information.info?.query });
      setTimeout(() => {
        setNotifier(!notifier);
      }, 1500);
    }
  }, [information]);

  if (information.loading) {
    return <Loader />;
  }

  if (information.error) {
    return <ErrorPage />;
  }

  return (
    <div className="mt-5 md:mt-0 w-5/6">
      {/* tag */}
      <TagShortDecription
        title="Administrative"
        description="This is section is only visible to administrators"
        outerlayerStyle="md:col-span-1"
        innerBodyStyle="px-4 sm:px-0 my-10"
        innerHeaderStyle="text-lg font-medium leading-6 text-gray-900"
        innerlayerStyle="mt-1 text-sm text-gray-600"
      />

      {/* ssearch */}
      <div className="bg-white my-5 p-3 lg:p-5 md:p-10 focus:ring-indigo-500 shadow">
        <SearchBar searching={(v) => handleSearch(v)} />
        <ControlButton
          onClick={() => explore({ isOpen: !explored.isOpen, editor: 1 })}
          toggleCondition={explored.isOpen}
          authenticity={pageSettings.item.counter && isAdmin}
          totalCount={information.info?.HLL?.total}
        />
      </div>

      {/* edit section */}
      {explored.isOpen && (
        <div className=" border border-white shadow">
          <ActionButton
            onClickEditor={() => explore({ isOpen: true, editor: 1 })}
            styliCondition={explored.editor}
            settings={{
              control: false,
              editor: true,
            }}
          />

          <div className="lg:grid lg:grid-flow-col lg:gap-3">
            {explored.editor === 1 && (
              <CreateOrUpdateForm
                exemptPage={pageSettings.pageName}
                schema={formSchema}
                schemaSelect={pageSettings.schema.form}
                item={explored.item}
                submit={handleSubmit}
                clear={() => {
                  setextensionFormValues({
                    address: null,
                    category: null,
                    subcategory: null,
                    brand: null,
                    subpscriptionPlanType: null,
                    subpscriptionPlanPeriod: null,
                    shop: null,
                    currency: null,
                  });
                  explore({
                    ...explored,
                    item: null,
                    currentItemOriginalDetails: null,
                  });
                }}
                category={{
                  onPick: (v) => {
                    if (typeof v === "object") {
                      return setextensionFormValues({
                        ...extensionFormValues,
                        category_name: v?.name,
                      });
                    }
                    return setextensionFormValues({
                      ...extensionFormValues,
                      category_name: v,
                    });
                  },
                  title: "Name",
                  schema: pageSettings.schema.formExtension[0].name,
                  displayValuePropertyIndex: 1,
                  listValueType: "objects",
                  required: true,
                  defaultValueOver: explored?.currentItemOriginalDetails?.name,
                  enableDefaultValueOver: true,
                }}
                subcategory={{
                  onPick: (v) => {
                    if (typeof v === "object") {
                      return setextensionFormValues({
                        ...extensionFormValues,
                        subcategory_name: v?.name,
                        serial: v?.serial,
                      });
                    }
                    return setextensionFormValues({
                      ...extensionFormValues,
                      subcategory_name: v,
                    });
                  },
                  title: "Subcategory Name",
                  schema: pageSettings.schema.formExtension[1].name,
                  displayValuePropertyIndex: 1,
                  listValueType: "objects",
                  required: true,
                }}
                currency={{
                  onPick: (v) =>
                    setextensionFormValues({
                      ...extensionFormValues,
                      commission_currency: v?.code,
                    }),
                  title: "Commission Currency",
                  schema: pageSettings.schema.formExtension[2].name,
                  listValueType: "objects",
                  displayValuePropertyIndex: 3,
                  required: true,
                  defaultValueOver:
                    explored?.currentItemOriginalDetails?.commission_currency,
                  enableDefaultValueOver: true,
                }}
                onChange={() => ""} //to keep clear on console
              />
            )}
          </div>
        </div>
      )}

      {/* list */}
      <TableLayout
        list={listed}
        pagination={{
          from: information.info?.HLL?.from,
          to: information.info?.HLL?.to,
          total: information.info?.HLL?.total,
          current: information.info?.HLL?.current_page,
          last: information.info?.HLL?.last_page,
          goto: (pageNumber: number) =>
            information.getCategory({ page: pageNumber }),
        }}
      />
    </div>
  );
}
export default Categories;

function filterInformation(info: any) {
  let selectedDetails = {
    ID: info?.id,
    name: info?.name,
    commission_currency: info?.commission_currency,
    commission_flat_rate: info?.commission_flat_rate,
    commission_real_value: info?.commission_monthly,
    subcategories: info?.subcategories
      ?.map((v, i) => i + 1 + ")." + v.name)
      .join(",\n"),
  };
  return selectedDetails;
}
