import classNames from "classnames";
import React, { SyntheticEvent, useState } from "react";

import { formSchema } from "../../constants/form-names";
import { CreateOrUpdateForm, PermissionForm } from "../ui/create-or-update.tsx";
import SearchBar from "../ui/search-bar";
import TableLayout from "../ui/table";
import { TagShortDecription } from "../ui/tag-short-decription";
import { useModal } from "../ui/modal/modal.context";
import Loader from "../ui/spinner";
import { usePermission } from "../hook/permission";
import { useSearchEngineFull } from "../hook/search-engine";
import { ErrorPage } from "./error";
import { useDeletions } from "../hook/use-deletions";
import { usePersonal } from "../hook/user-detail";
import { submitter } from "../hook/submitter";
import ControlButton from "../ui/button/control-button";
import ActionButton from "../ui/button/action-button";
import { ExtensionFormValuesProps } from "../../types";

const pageSettings = {
  pageName: "deletions",
  item: {
    counter: true,
  },
  schema: {
    form: "deletionRequestForm",
    option: "deletionsOptionForm",
    formKeys: [
      { title: "No Action", action: "No action" },
      { title: "Delete Permanently", action: "Deleted" },
      { title: "Cancel Delete", action: "Deletion rejected by admin" },
    ],
    searchColumns: ["id", "customer", "feedback"],
    searchIndex: 22,
    formExtension: [],
  },
  explore: {
    isOpen: false,
    editor: 0,
    item: null,
    currentItemOriginalDetails: null,
  },
  control: {
    allowedControl: true,
    restrictions: [],
  },
};

function Deletions() {
  const { data } = usePersonal(true);
  const isAdmin = usePermission(2);
  const { openModal } = useModal();
  const [explored, explore] = useState<typeof pageSettings.explore | any>(
    pageSettings.explore
  );
  const [notifier, setNotifier] = useState<boolean>(false);
  const searcher = useSearchEngineFull();
  const deletions = useDeletions();
  const [extensionFormValues, setextensionFormValues] = React.useState<any>("");

  //preset calls
  let owner: any = data;
  const information = deletions,
    listInformation = searcher.info?.HLL ?? deletions.info?.HLL?.data;

  React.useEffect(() => {
    deletions.getDeletions({ page: 1 });
  }, []);

  //___list
  //select on list
  const handleSelect = (item) => {
    const currentItemOriginalDetails = information.info?.HLL?.data?.find(
      (v) => v.id === item.ID
    );
    return explore({
      isOpen: true,
      editor: 1,
      item,
      currentItemOriginalDetails,
    });
  };
  //regenerate list with columns' title
  const listed =
    typeof information.info === "object" &&
    information.info?.HLL?.data?.length !== undefined
      ? listInformation.map((item) => {
          // console.log("original", item);
          const selectedDetail = filterInformation(item);
          let currentProperties = Object.entries(selectedDetail),
            columns = currentProperties.map((property) => {
              return {
                title:
                  property[0] === "is_active"
                    ? "Status"
                    : property[0].charAt(0).toUpperCase() +
                      property[0]?.replace(/[(_)(-)]/gi, " ").slice(1),
                dataIndex: property[0],
                key: property[0],
                render: (value, row) => (
                  <div
                    className={classNames(
                      "text-justify mx-5 cursor-pointer text-[15px]",
                      {
                        "bg-green-800 rounded-full h-3 w-3":
                          property[0] === "is_active" && (value || value === 1),
                      },
                      {
                        "bg-red-800 rounded-full h-3 w-3":
                          property[0] === "is_active" &&
                          (!value || value === 0),
                      }
                    )}
                    onClick={() => handleSelect(row)}
                    onDoubleClick={() =>
                      openModal({ view: "ALERT", payload: value })
                    }
                  >
                    {property[0] !== "is_active" && value}
                  </div>
                ),
              };
            });
          return {
            ...selectedDetail,
            columns,
          };
        })
      : [];

  //searching
  const handleSearch = (searchTerm) => {
    searcher.findRelated({
      keyIndex: pageSettings.schema.searchIndex,
      value: searchTerm,
      targets: pageSettings.schema.searchColumns,
    });
  };

  //create or update
  const handleSubmit = (e) => {
    e.preventDefault();

    if (explored.item.is_active === 1) {
      return openModal({
        view: "ALERT",
        payload: "This user have been previously deleted",
      });
    }

    setNotifier(!notifier);
    information.createOrUpdateDeletions({
      ...explored.item,
      ...extensionFormValues,
      id: explored.item.ID,
      is_active:
        extensionFormValues?.deletion_status === "Deleted" ? true : false,
    });
  };

  React.useEffect(() => {
    if (notifier && information.error) {
      openModal({ view: "ALERT", payload: information.error });
      setTimeout(() => {
        setNotifier(!notifier);
      }, 1500);
    } else if (notifier && information.info?.query) {
      openModal({ view: "ALERT", payload: information.info?.query });
      setTimeout(() => {
        setNotifier(!notifier);
      }, 1500);
    }
  }, [information]);

  if (information.loading) {
    return <Loader />;
  }

  if (information.error) {
    return <ErrorPage />;
  }

  return (
    <div className="mt-5 md:mt-0 w-5/6">
      {/* tag */}
      <TagShortDecription
        title="Administrative"
        description="This is section is only visible to administrators"
        outerlayerStyle="md:col-span-1"
        innerBodyStyle="px-4 sm:px-0 my-10"
        innerHeaderStyle="text-lg font-medium leading-6 text-gray-900"
        innerlayerStyle="mt-1 text-sm text-gray-600"
      />

      {/* ssearch */}
      <div className="bg-white my-5 p-3 lg:p-5 md:p-10 focus:ring-indigo-500 shadow">
        <SearchBar searching={(v) => handleSearch(v)} />
        <ControlButton
          onClick={() => explore({ isOpen: !explored.isOpen, editor: 1 })}
          toggleCondition={explored.isOpen}
          authenticity={pageSettings.item.counter && isAdmin}
          totalCount={listInformation?.length}
        />
      </div>

      {/* edit section */}
      {explored.isOpen && (
        <div className=" border border-white shadow">
          <ActionButton
            onClickEditor={() => explore({ isOpen: true, editor: 1 })}
            onClickControl={() => ""}
            styliCondition={explored.editor}
          />

          <div className="mx-10 my-5">
            <select
              onChange={(v) =>
                setextensionFormValues({
                  deletion_status: v.target.value,
                })
              }
            >
              {pageSettings.schema.formKeys.map((value, index) => (
                <option key={index} value={value.action}>
                  {value.title}
                </option>
              ))}
            </select>
            <button
              type="button"
              onClick={handleSubmit}
              className="my-3 mx-1 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-500 hover:bg-indigo-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Save
            </button>
          </div>
        </div>
      )}

      {/* list */}
      <TableLayout
        list={listed}
        pagination={{
          from: information.info?.HLL?.from,
          to: information.info?.HLL?.to,
          total: information.info?.HLL?.total,
          current: information.info?.HLL?.current_page,
          last: information.info?.HLL?.last_page,
          goto: (pageNumber: number) =>
            information.getDeletions({ page: pageNumber }),
        }}
      />
    </div>
  );
}
export default Deletions;

function filterInformation(info: any) {
  let selectedDetails = {
    is_active: info?.is_active,
    ID: info?.id,
    deletion_status: info?.deletion_status,
    customer: info?.customer,
    feedback: info?.feedback,
    customer_id: info?.customer_id,
  };
  return selectedDetails;
}
