import React from "react";
import { useDispatch, useSelector, useStore } from "react-redux";

import {
  distancesAction,
  distancesSelector,
  initialState,
} from "../../redux/reducers/distances-reducer";

type UseDistancesProps = {
  createOrUpdateDistances: (payload?: any) => any;
  getDistances: (payload?: any) => any;
  deleteDistances: (payload?: any) => any;
} & typeof initialState;

export const useDistances = (): UseDistancesProps => {
  const dispatch = useDispatch();
  const store = useStore();
  const state = useSelector(distancesSelector);

  const createOrUpdateDistances = (payload: any) =>
    dispatch<any>(distancesAction({ path: "/distance", payload }));
  const getDistances = (payload: any) =>
    dispatch<any>(distancesAction({ path: "/distances", payload }));
  const deleteDistances = (payload: any) =>
    dispatch<any>(distancesAction({ path: "/distance/status", payload }));

  return {
    ...state,
    createOrUpdateDistances,
    getDistances,
    deleteDistances,
  };
};
