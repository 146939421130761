import React from "react";
import { useLocation } from "react-router-dom";
import {
  accessControlAdmin,
  accessControlSeller,
} from "../../constants/access-control";
import { usePermission } from "../hook/permission";
import { usePersonal } from "../hook/user-detail";

import { useRemoteControl } from "../ui/modal/toggler";
import { Sidebar } from "../ui/sidebar";

function SidebarNavigaton({ children }: any) {
  const isAdmin = usePermission(2);
  const { payload } = useRemoteControl();
  const location = useLocation();
  const isAdminSeller = usePermission(2),
    { data } = usePersonal(true);

  const onwner: any = data,
    // @ts-ignore
    parseValues = JSON.parse(onwner?.granted_accesses ?? "[]") ?? [],
    accessibleMenuList = (
      naviagtionMenu: typeof accessControlAdmin
    ): typeof accessControlAdmin => {
      let menuItems = naviagtionMenu.filter((current) => {
        let status = parseValues.find(
          (access: any) => access.toLowerCase() === current.token.toLowerCase()
        );
        if (
          current.token.toLowerCase().includes("my") ||
          current.token.toLowerCase().includes("dashboard") ||
          Boolean(status)
        ) {
          return current;
        }
      });
      return menuItems;
    };

  const routename = location.pathname.slice(1).toLowerCase(),
    isPublicRoutes = Boolean(
      ["reset", "login", "register"].find(
        (routeName) => routeName === routename
      )
    ),
    sidebarLinksNavigation = isAdmin
      ? accessibleMenuList(accessControlAdmin)
      : accessControlSeller;

  return (
    <>
      {!isPublicRoutes ? (
        <div className="sidebar-navigaton">
          <div className="mt-0 sm:mt-0 grid-flow-col lg:grid lg:gap-2 md:grid md:gap-2">
            {payload && (
              <div className="block lg:hidden md:hidden bg-white border-r shadow pt-2 px-10">
                <Sidebar list={sidebarLinksNavigation} />
              </div>
            )}
            <div className="hidden lg:block md:block bg-white border-r shadow pt-2 px-10 min-h-screen">
              <Sidebar list={sidebarLinksNavigation} />
            </div>
            <div className="mt-5 md:mt-0 flex flex-col items-center  col-span-9 overflow-auto">
              {children}
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
}
export default SidebarNavigaton;
