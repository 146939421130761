import classNames from "classnames";
import React, { SyntheticEvent, useState } from "react";

import { formSchema } from "../../constants/form-names";
import { CreateOrUpdateForm, PermissionForm } from "../ui/create-or-update.tsx";
import SearchBar from "../ui/search-bar";
import TableLayout from "../ui/table";
import { TagShortDecription } from "../ui/tag-short-decription";
import { useModal } from "../ui/modal/modal.context";
import Loader from "../ui/spinner";
import { usePermission } from "../hook/permission";
import { useSearchEngineFull } from "../hook/search-engine";
import { ErrorPage } from "./error";
import { useShop } from "../hook/use-shop";
import { usePersonal } from "../hook/user-detail";
import { submitter } from "../hook/submitter";
import { SubmitterPermission } from "../hook/submitter-permission";
import ControlButton from "../ui/button/control-button";
import ActionButton from "../ui/button/action-button";
import {
  ExtensionFormValuesProps,
  PermissionFormValuesProps,
} from "../../types";

const pageSettings = {
  pageName: "shops",
  item: {
    counter: true,
  },
  schema: {
    form: "shopForm",
    option: "shopOptionForm",
    formKeys: formSchema
      .filter((v) => v.schema === "shopForm")
      .map((v) => v.key),
    searchColumns: ["id"].concat(
      formSchema
        .filter((v) => v.schema === "shopForm")
        .map((v) => v.key)
        .slice(0, 1) as any[]
    ),
    searchIndex: 10,
    formExtension: [{ name: "category", title: "Category" }],
  },
  explore: {
    isOpen: false,
    editor: 0,
    item: null,
    currentItemOriginalDetails: null,
  },
  control: {
    allowedControl: true,
    restrictions: [],
  },
};

function MyShops() {
  const { data } = usePersonal(true);
  const isAdmin = usePermission(2);
  const { openModal } = useModal();
  const [explored, explore] = useState<typeof pageSettings.explore | any>(
    pageSettings.explore
  );
  const [notifier, setNotifier] = useState<boolean>(false);
  const searcher = useSearchEngineFull();
  const shops = useShop();
  const [permissionFormValues, setpermissionFormValues] =
    React.useState<PermissionFormValuesProps>({
      is_active: false,
      ability: "",
      type: "",
      premier: "",
      product_type: "",
      state: "",
      id: null,
    });
  const [extensionFormValues, setextensionFormValues] =
    React.useState<ExtensionFormValuesProps>({
      address: null,
      category: null,
      subcategory: null,
      brand: null,
      subpscriptionPlanType: null,
      subpscriptionPlanPeriod: null,
      shop: null,
      currency: null,
    });

  //preset calls
  let owner: any = data;
  const information = shops,
    listInformation = searcher.info?.HLL ?? shops.info?.HLL?.data;

  React.useEffect(() => {
    shops.getShop({ page: 1 });
  }, []);

  //___list
  //select on list
  const handleSelect = (item) => {
    const currentItemOriginalDetails = information.info?.HLL?.data.find(
      (v) => v.id === item.ID
    );
    return explore({
      isOpen: true,
      editor: 1,
      item,
      currentItemOriginalDetails,
    });
  };
  //regenerate list with columns' title
  const listed =
    typeof information.info === "object" &&
    information.info?.HLL?.data?.length !== undefined
      ? listInformation
          .filter((v) => v?.owner_id === owner?.id)
          .map((item) => {
            const selectedDetail = filterInformation(item);
            let currentProperties = Object.entries(selectedDetail),
              columns = currentProperties.map((property) => {
                return {
                  title:
                    property[0] === "is_active"
                      ? "Status"
                      : property[0].charAt(0).toUpperCase() +
                        property[0]?.replace(/[(_)(-)]/gi, " ").slice(1),
                  dataIndex: property[0],
                  key: property[0],
                  render: (value, row) => (
                    <div
                      className={classNames(
                        "text-justify mx-5 cursor-pointer text-[15px]",
                        {
                          "bg-green-800 rounded-full h-3 w-3":
                            property[0] === "is_active" &&
                            (value || value === 1),
                        },
                        {
                          "bg-red-800 rounded-full h-3 w-3":
                            property[0] === "is_active" &&
                            (!value || value === 0),
                        }
                      )}
                      onClick={() => handleSelect(row)}
                      onDoubleClick={() =>
                        openModal({ view: "ALERT", payload: value })
                      }
                    >
                      {property[0] !== "is_active" && value}
                    </div>
                  ),
                };
              });
            return {
              ...selectedDetail,
              columns,
            };
          })
      : [];

  //searching
  const handleSearch = (searchTerm) => {
    searcher.findRelated({
      keyIndex: pageSettings.schema.searchIndex,
      value: searchTerm,
      targets: pageSettings.schema.searchColumns,
    });
  };

  //create or update
  const handleSubmit = (e) => {
    e.preventDefault();

    let payload = submitter(e, extensionFormValues, explored, owner);

    setNotifier(!notifier);
    information.createOrUpdateShop(payload);
  };
  // acitvation and deactivation
  const handleActivation = () => {
    let inputValue = SubmitterPermission(pageSettings, permissionFormValues),
      payload = {
        ...inputValue,
        id: explored?.currentItemOriginalDetails?.id,
      };
    setNotifier(!notifier);
    information.deactivateShop(payload);
  };

  React.useEffect(() => {
    if (notifier && information.error) {
      openModal({ view: "ALERT", payload: information.error });
      setTimeout(() => {
        setNotifier(!notifier);
      }, 1500);
    } else if (notifier && information.info?.query) {
      openModal({ view: "ALERT", payload: information.info?.query });
      setTimeout(() => {
        setNotifier(!notifier);
      }, 1500);
    }
  }, [information]);

  if (information.loading) {
    return <Loader />;
  }

  if (information.error) {
    return <ErrorPage />;
  }

  return (
    <div className="mt-5 md:mt-0 w-5/6">
      {/* tag */}
      <TagShortDecription
        title="Administrative"
        description="This is section is visible to authorized person(s)"
        outerlayerStyle="md:col-span-1"
        innerBodyStyle="px-4 sm:px-0 my-10"
        innerHeaderStyle="text-lg font-medium leading-6 text-gray-900"
        innerlayerStyle="mt-1 text-sm text-gray-600"
      />

      {/* ssearch */}
      <div className="bg-white my-5 p-3 lg:p-5 md:p-10 focus:ring-indigo-500 shadow">
        <SearchBar searching={(v) => handleSearch(v)} />
        <ControlButton
          onClick={() => explore({ isOpen: !explored.isOpen, editor: 1 })}
          toggleCondition={explored.isOpen}
          authenticity={pageSettings.item.counter && isAdmin}
          totalCount={information.info?.HLL?.total}
        />
      </div>

      {/* edit section */}
      {explored.isOpen && (
        <div className=" border border-white shadow">
          <ActionButton
            onClickEditor={() => explore({ isOpen: true, editor: 1 })}
            onClickControl={() =>
              explore({ ...explored, isOpen: true, editor: 2 })
            }
            styliCondition={explored.editor}
          />

          <div className="lg:grid lg:grid-flow-col lg:gap-3">
            {explored.editor === 1 && (
              <CreateOrUpdateForm
                schema={formSchema}
                schemaSelect={pageSettings.schema.form}
                item={explored.item}
                submit={handleSubmit}
                clear={() => {
                  setextensionFormValues({
                    address: null,
                    category: null,
                    subcategory: null,
                    brand: null,
                    subpscriptionPlanType: null,
                    subpscriptionPlanPeriod: null,
                    shop: null,
                    currency: null,
                  });
                  explore({
                    ...explored,
                    item: null,
                    currentItemOriginalDetails: null,
                  });
                }}
                autoTitle={true}
                countrySelected={(v: any) => {
                  setextensionFormValues({
                    ...extensionFormValues,
                    address: {
                      ...extensionFormValues.address,
                      country: v.country_name,
                      country_details: {
                        ...extensionFormValues.address?.country_details,
                        ...v,
                      },
                    },
                  });
                }}
                stateSelected={(v: any) => {
                  setextensionFormValues({
                    ...extensionFormValues,
                    address: {
                      ...extensionFormValues.address,
                      state: v.state_name,
                      state_details: {
                        ...extensionFormValues.address?.state_details,
                        ...v,
                      },
                    },
                  });
                }}
                citySelected={(v: any) => {
                  setextensionFormValues({
                    ...extensionFormValues,
                    address: {
                      ...extensionFormValues.address,
                      city: v.city_name,
                      city_details: {
                        ...extensionFormValues.address?.city_details,
                        ...v,
                      },
                    },
                  });
                }}
                defaultCountry={explored?.item?.country}
                defaultState={explored?.item?.state}
                defaultCity={explored?.item?.city}
                category={{
                  onPick: (v) =>
                    setextensionFormValues({
                      ...extensionFormValues,
                      category: {
                        ...extensionFormValues.category,
                        name: v.name,
                        id: v.id,
                        category_id: v.id,
                      },
                    }),
                  title: pageSettings.schema.formExtension[0].title,
                  schema: pageSettings.schema.formExtension[0].name,
                  displayValuePropertyIndex: 1,
                  listValueType: "objects",
                  required: true,
                }}
              />
            )}
            {explored.editor === 2 && (
              <PermissionForm
                schema={formSchema}
                item={explored.item}
                schemaSelect={pageSettings.schema.option}
                submit={(e: SyntheticEvent) => {
                  e.preventDefault();
                  handleActivation();
                }}
                onChange={(v) =>
                  setpermissionFormValues({
                    ...permissionFormValues,
                    is_active: v?.title === "Active" ? 1 : 0,
                  })
                }
                defaultChecked={explored?.currentItemOriginalDetails?.is_active}
              />
            )}
          </div>
        </div>
      )}

      {/* list */}
      <TableLayout
        list={listed}
        pagination={{
          from: information.info?.HLL?.from,
          to: information.info?.HLL?.to,
          total: information.info?.HLL?.total,
          current: information.info?.HLL?.current_page,
          last: information.info?.HLL?.last_page,
          goto: (pageNumber: number) =>
            information.getShop({ page: pageNumber }),
        }}
      />
    </div>
  );
}
export default MyShops;

function filterInformation(info: any) {
  let nationality =
    (info?.address?.city?.city_name ?? info?.address?.city) +
    ", " +
    (info?.address?.state?.state_name ?? info?.address?.state) +
    ", " +
    (info?.address?.country?.country_name ?? info?.address?.country);

  let selectedDetails = {
    is_active: info?.is_active,
    ID: info?.id,
    name: info?.name,
    description: info?.description,
    category: info?.category?.name,
    street_name: info?.address?.street_name,
    location: nationality,
    zip: info?.address?.zip,
    seller_name: info?.seller?.name,
    bank_name: info?.bank?.bank_name,
    bank_country: info?.bank?.bank_country,
    swift_code: info?.bank?.swift_code,
    sort_code: info?.bank?.sort_code,
    account_name: info?.bank?.account_name,
    account_number: info?.bank?.account_number,
    paypal_email: info?.paypal?.paypal_email,
  };
  return selectedDetails;
}
