import React from 'react'

type ControlButtonProps = {
    onClick?:any
    totalCount?:any
    authenticity?:any
    toggleCondition?:any
    settings?:{
      editor?:boolean
      control?:boolean
    }
}

function ControlButton({ 
  onClick, 
  totalCount, 
  authenticity, 
  toggleCondition,
}: ControlButtonProps) {

    return (
    <div className="px-4 py-3 bg-gray-50 sm:px-6 flex justify-between border border-gray-100 rounded-lg">
        <button
          type="button"
          onClick={onClick}
          className="py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-black hover:bg-white hover:border-black hover:text-black focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          {!toggleCondition ? "Explore" : "Close"}
        </button>
        {authenticity && 
        <div
          className="mx-1 py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-black bg-gray-300 hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          {"Count: "+ (totalCount ?? 0)}
        </div>}
    </div>
    )
}

export default ControlButton
