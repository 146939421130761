import ROUTES from "./routes-list";

export const accessControlAdmin = [
  {
    token: "dashboard",
    label: "Dashboard",
    href: ROUTES.DASHBOARD,
    icon: "",
    permissions: 2,
  },
  {
    label: "My Shops",
    token: "my-shops",
    href: ROUTES.MY_SHOPS,
    icon: "",
    permissions: 2,
  },
  {
    label: "My Products",
    token: "my-products",
    href: ROUTES.MY_PRODUCTS,
    icon: "",
    permissions: 2,
  },
  {
    label: "My Orders",
    token: "my-orders",
    href: ROUTES.MY_ORDERS,
    icon: "",
    permissions: 2,
  },
  {
    label: "Shops",
    token: "shops",
    href: ROUTES.SHOPS,
    icon: "",
    permissions: 3,
  },
  {
    label: "Products",
    token: "products",
    href: ROUTES.PRODUCTS,
    icon: "",
    permissions: 3,
  },
  {
    label: "Orders",
    token: "orders",
    href: ROUTES.ORDERS,
    icon: "",
    permissions: 3,
  },
  {
    label: "Subscription Plans",
    token: "subscription-plans",
    href: ROUTES.SUBSCRIPTION_PLAN,
    icon: "",
    permissions: 3,
  },
  {
    label: "Subscriptions",
    token: "subscriptions",
    href: ROUTES.SUBSCRIPTION,
    icon: "",
    permissions: 3,
  },
  {
    label: "category",
    token: "category",
    href: ROUTES.CATEGORY,
    icon: "",
    permissions: 3,
  },
  {
    label: "Support",
    token: "support",
    href: ROUTES.SUPPORTS,
    icon: "",
    permissions: 3,
  },
  {
    label: "Job Posts",
    token: "job-posts",
    href: ROUTES.JOB,
    icon: "",
    permissions: 3,
  },
  {
    label: "Job Applicants",
    token: "job-applicants",
    href: ROUTES.APPLICANTS,
    icon: "",
    permissions: 3,
  },
  {
    label: "Users",
    token: "users",
    href: ROUTES.USERS,
    icon: "",
    permissions: 3,
  },
  {
    label: "Brands",
    token: "brands",
    href: ROUTES.BRANDS,
    icon: "",
    permissions: 3,
  },
  {
    label: "Mail templates",
    token: "mail-templates",
    href: ROUTES.TEMPLATES,
    icon: "",
    permissions: 3,
  },
  {
    label: "Order Status",
    token: "order-status",
    href: ROUTES.ORDER_STATUS,
    icon: "",
    permissions: 3,
  },
  {
    label: "Curencies Exchange",
    token: "currencies-exchange",
    href: ROUTES.EXCHANGES,
    icon: "",
    permissions: 3,
  },
  {
    label: "Shipping Clases",
    token: "shipping-classes",
    href: ROUTES.SHIPPING,
    icon: "",
    permissions: 3,
  },
  {
    label: "Mile Prices",
    token: "mile-prices",
    href: ROUTES.MILES,
    icon: "",
    permissions: 3,
  },
  {
    label: "Custom Distances",
    token: "custom-distances",
    href: ROUTES.DISTANCE,
    icon: "",
    permissions: 3,
  },
  {
    label: "Deletion Requests",
    token: "deletion-requests",
    href: ROUTES.DELETION,
    icon: "",
    permissions: 3,
  },
  {
    label: "Chat Logs",
    token: "chat-logs",
    href: ROUTES.CHATLOGS,
    icon: "",
    permissions: 3,
  },
];

export const accessControlSeller = [
  {
    token: "dashboard",
    label: "Dashboard",
    href: ROUTES.DASHBOARD,
    icon: "",
    permissions: 2,
  },
  {
    label: "Shops",
    token: "shops",
    href: ROUTES.MY_SHOPS,
    icon: "",
    permissions: 2,
  },
  {
    label: "Orders",
    token: "orders",
    href: ROUTES.MY_ORDERS,
    icon: "",
    permissions: 2,
  },
  {
    label: "Products",
    token: "products",
    href: ROUTES.MY_PRODUCTS,
    icon: "",
    permissions: 2,
  },
];
