import React from 'react';
import Table from 'rc-table';

import Pagination from './pagination';

type PaginationProps = {
    from:number
    to:number
    total:number
    current:number
    last:number
    goto:any
  
  }
type TableLayoutProps = {
    list:any[]
    pagination?:PaginationProps
}
/**
 * version upgraded table script
 */ 
function TableLayout({ list, pagination }:TableLayoutProps) {

    return(
        <div className='bg-white my-5 p-5 text-center shadow'>
            <Table columns={list[0]?.columns} 
                data={list} 
                className="overflow-auto"
                scroll={{x: true,}}
                rowClassName='border-t border-[#f0f0f0] hover:bg-[#f0f0f0]'
                rowKey={(record)=>record.id}
                footer={()=>{
                    return (
                        pagination?.from ?
                        <Pagination 
                            from={pagination?.from!}
                            to={pagination?.to!}
                            total={pagination?.total!}
                            current={pagination?.current!}
                            last={pagination?.last!}
                            goto={pagination?.goto!}
                        />
                        :<></>
                    )
                    
                }}
            />
        </div>
    )
}

export default TableLayout;