import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { orderStatusAction, initialState, orderStatusSelector } from "../../redux/reducers/order-status-reducer";

type UseOrderStatusProps = {
    createOrUpdateOrderStatus:(payload:any) => any 
    getOrderStatus:(payload:any) => any 
    deactivateOrderStatus:(payload:any) => any 
    deleteOrderStatus:(payload:any) => any

} & typeof initialState

export const useOrderStatus = ():UseOrderStatusProps => {
    const dispatch = useDispatch();
    const state = useSelector(orderStatusSelector);


    const createOrUpdateOrderStatus = (payload:any) => dispatch<any>(orderStatusAction({path:"/orderStatus", payload}));
    const getOrderStatus = (payload:any) => dispatch<any>(orderStatusAction({path:"/orderStatuss", payload}));
    const deactivateOrderStatus = (payload:any) => dispatch<any>(orderStatusAction({path:"/orderStatus/status", payload}));
    const deleteOrderStatus = (payload:any) => dispatch<any>(orderStatusAction({path:"/orderStatus/status", payload,}));

    return {
        ...state,
        createOrUpdateOrderStatus, 
        getOrderStatus, 
        deactivateOrderStatus, 
        deleteOrderStatus
    }
}