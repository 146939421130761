import classNames from "classnames";
import React from "react";
import { Card, PackageCard } from "./card";


type CheckDefultProps = {
    type?:string
    name:string
    autoComplete?:string
    required?:boolean
    classNameCheck?:string[] | string
    defaultChecked?:boolean | string | number | string[]
    htmlFor?:string
    classNameLabel?:string[] | string
    labelTitle?:string
    classNameGrouped?:string
    onChange:(arg:any)=>any
    list?:any
    classNameGroupedList?:any
    useDisplayComponent?:boolean
    classNameGroupedTopLayer?:string
    colorization?:boolean
}
export const CheckBox = ({
    type = "text",
    name = "",
    autoComplete = "",
    required = false,
    classNameCheck,
    htmlFor = "",
    classNameLabel = "sr-only",
    labelTitle = "",
    classNameGrouped = "",
    onChange,
 }:CheckDefultProps) => {
    const styliCheck = typeof classNameCheck === 'object' 
        ? classNameCheck.join(" ") as string
        : classNameCheck;

    const styliLabel = typeof classNameLabel === 'object' 
        ? classNameLabel.join(" ") as string
        : classNameLabel;

    let label = typeof name  === 'string' ? name.replace(/(\s)/gi, "-") :  "";
    return (
        <div className={classNameGrouped}>
            <input
                id={htmlFor ?? label}
                name={name}
                type={type}
                autoComplete={autoComplete ?? name}
                required={required}
                className={styliCheck}
                onChange={onChange}
            />
            <label htmlFor={htmlFor ?? label} className={styliLabel}>
                {labelTitle}
            </label>
        </div>
    )
}
export const CheckBoxWithOption = ({
    type = "text",
    name = "",
    autoComplete = "",
    required = false,
    classNameCheck,
    htmlFor = "",
    classNameLabel = "sr-only",
    classNameGrouped = "",
    onChange,
    classNameGroupedList,
    list=[],
    useDisplayComponent,
    labelTitle,
    classNameGroupedTopLayer,
    defaultChecked,
    colorization = true,
 }:CheckDefultProps) => {
    const [isChecked, isCheck] = React.useState<{ index:number, status:boolean }>({ index:0, status:false });
    const [listedValue, listValue] = React.useState<any[]>(
        ()=>list.map((item)=>{
            if (typeof item === 'string') {
                return ({
                    title:item,
                    status:false 
                })
            }
            return {
                ...item,
                status:false 
            }
        })
    );
    const styliCheck = typeof classNameCheck === 'object' 
        ? classNameCheck.join(" ") as string
        : classNameCheck;

    const styliLabel = typeof classNameLabel === 'object' 
        ? classNameLabel.join(" ") as string
        : classNameLabel;

    let label = typeof name  === 'string' ? name.replace(/(\s)/gi, "-") :  "";
    return (<div className={classNameGroupedTopLayer}>
            {labelTitle && <div className="flex justify-start items-center">
                {typeof defaultChecked !== "object" && <div className={classNames(
                    "rounded-full h-3 w-3 m-1",
                    { "bg-green-800":( defaultChecked === true || defaultChecked === 1 )},
                    { "bg-red-800":( defaultChecked === false || defaultChecked === 0 )}
                )}></div>}
                {typeof defaultChecked === "object" && labelTitle === "Premier" && <div className="pr-2">{"("+defaultChecked[0]+")"}</div>}
                {typeof defaultChecked === "object" && labelTitle === "Status" && <div className="pr-2">{"("+defaultChecked[1]+")"}</div>}
                {typeof defaultChecked === "object" && labelTitle === "State" && <div className="pr-2">{"("+defaultChecked[2]+")"}</div>}
                {labelTitle+": "}
            </div>}
            <div className={classNameGroupedList}>
                {listedValue.map((item, index)=>(<div key={index} className={classNameGrouped}>
                    <input
                        id={htmlFor ?? label}
                        name={name}
                        type={type}
                        autoComplete={autoComplete ?? name}
                        required={required}
                        className={classNames(
                            styliCheck,
                            {"border border-green-800": colorization && index === 0},
                            {"border border-red-800": colorization && index === 1}
                        )}
                        // defaultChecked={isChecked.index !== index ? !isChecked.status : false }
                        // checked={isChecked.index === index ? isChecked.status : false }
                        onChange={(e)=>{
                            // @ts-ignore
                            onChange(item);
                            // listValue(()=>{
                            //     let listedItems:any[] = list.map((current, i)=>{
                            //         if (i === index) {
                            //             return {
                            //                 ...current,
                            //                 status:true 
                            //             }
                            //         }
                            //         return {
                            //             ...current,
                            //             status:false 
                            //         }
                            //     });
                            //     return listedItems;
                            // });

                        }}
                    />
                    {!useDisplayComponent?
                        <label htmlFor={htmlFor ?? label} className={styliLabel}>
                            {item?.title}
                        </label>
                        :
                        <Card 
                            item={item}
                            cardStyli="mx-10 my-5 shadow"
                        />
                    }
                </div>))}
            </div>
        </div>
    )
}
