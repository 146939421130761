import React from "react";
import {
  LibraryIcon,
  CollectionIcon,
  CashIcon,
  CurrencyDollarIcon,
  IdentificationIcon,
  DocumentIcon,
  BriefcaseIcon,
  ArchiveIcon,
  CreditCardIcon,
} from "@heroicons/react/outline";
import axios from "axios";

import { baseURl } from "../../redux/env/host-url";
import { ActionProps } from "../../types";
import { usePermission } from "../hook/permission";
import { useConverter } from "../hook/currency-conversion";
import { usePersonal } from "../hook/user-detail";

function Landing() {
  const isAdmin = usePermission(2);
  const { UiForex, currency_name, iforexWS } = useConverter();
  const { data } = usePersonal(true);
  const [users, saveUsers] = React.useState<any>();
  const [subscriptions, setsubscriptions] = React.useState<any>();
  const [shops, setshops] = React.useState<any>();
  const [products, setproducts] = React.useState<any>();
  const [orders, setorders] = React.useState<any>();

  //preset call
  let owner: any = data;

  React.useEffect(() => {
    // users
    getList({
      path: "/search-dive",
      payload: {
        keyIndex: 0,
        value: "",
        targets: ["id"],
      },
    }).then(
      (v) => saveUsers(v),
      (e) => console.error(e)
    );

    // subscriptions
    getList({
      path: "/search-dive",
      payload: {
        keyIndex: 12,
        value: "",
        targets: ["id"],
      },
    }).then(
      (v) => setsubscriptions(v),
      (e) => console.error(e)
    );

    // shops
    getList({
      path: "/search-dive",
      payload: {
        keyIndex: 10,
        value: "",
        targets: ["id"],
      },
    }).then(
      (v) => setshops(v),
      (e) => console.error(e)
    );

    // products
    getList({
      path: "/search-dive",
      payload: {
        keyIndex: 7,
        value: "",
        targets: ["id"],
      },
    }).then(
      (v) => setproducts(v),
      (e) => console.error(e)
    );

    // orders
    getList({
      path: "/search-dive",
      payload: {
        keyIndex: 4,
        value: "",
        targets: ["id"],
      },
    }).then(
      (v) => setorders(v),
      (e) => console.error(e)
    );
  }, []);

  let usersList = users?.HLL,
    subscriptionsList = subscriptions?.HLL,
    ordersList = orders?.HLL,
    shopsList = shops?.HLL,
    productsList = products?.HLL;
  const Buyers = usersList?.filter((value) => {
      let abilities = value.abilities ?? "[1]";
      let authorization = JSON.parse(abilities);
      return authorization.length < 2;
    }),
    Sellers = usersList?.filter((value) => {
      let abilities = value.abilities ?? "[1]";
      let authorization = JSON.parse(abilities);
      return authorization.length > 1 && authorization.length < 3;
    }),
    Admins = usersList?.filter((value) => {
      let abilities = value.abilities ?? "[1]";
      let authorization = JSON.parse(abilities);
      return authorization.length >= 3;
    }),
    Adverts = subscriptionsList
      ?.filter((v) => v.type === "premier")
      .reduce(
        (prev: any, current: any) => {
          return {
            total: prev.total + 1,
            cost: prev.total + current.amount,
            currency: current.currency,
          };
        },
        { total: 0, cost: 0, currency: "" }
      ),
    Commissions = subscriptionsList
      ?.filter((v) => v.type === "premier")
      .reduce(
        (prev: any, current: any) => {
          return {
            total: prev.total + 1,
            cost: prev.total + current.amount,
            currency: current.currency,
          };
        },
        { total: 0, cost: 0, currency: "" }
      ),
    Premiers = subscriptionsList
      ?.filter((v) => v.type === "premier")
      .reduce(
        (prev: any, current: any) => {
          return {
            total: prev.total + 1,
            cost: prev.total + current.amount,
            currency: current.currency,
          };
        },
        { total: 0, cost: 0, currency: "" }
      ),
    adminOrders = ordersList?.reduce(
      (prev: any, current: any) => {
        let cost = iforexWS({
          from: current?.currency,
          value: current?.paid_total_fxws,
        });
        return {
          total: prev.total + 1,
          cost: prev.total + cost,
          currency: currency_name,
        };
      },
      { total: 0, cost: 0, currency: "" }
    ),
    SellerOrders = ordersList
      ?.filter((v) => v.customer_id === owner?.id)
      .reduce(
        (prev: any, current: any) => {
          let cost = iforexWS({
            from: current?.currency,
            value: current?.paid_total_fxws,
          });
          return {
            total: prev.total + 1,
            cost: prev.total + cost,
            currency: currency_name,
          };
        },
        { total: 0, cost: 0, currency: "" }
      ),
    SellerProducts = productsList?.filter(
      (v) => v?.shop?.owner_id === owner?.id
    ),
    SellerShops = shopsList?.filter((v) => v.owner_id === owner?.id),
    onwner: any = data,
    // @ts-ignore
    parseValues = JSON.parse(onwner?.granted_accesses ?? "[]") ?? [],
    accessibleCheck = (): boolean => {
      let status = parseValues.find(
        (access: any) =>
          access.toLowerCase().includes("mile-prices") ||
          access.toLowerCase().includes("shipping-classes") ||
          access.toLowerCase().includes("currencies-exchange")
      );
      return Boolean(status);
    };
  return (
    <div className="my-5 md:mt-0 w-5/6">
      {/* item relate */}
      <div className="sm:grid sm:grid-flow-col sm:gap-3 mt-[50px]">
        <div className="bg-white border border-gray-50 text-center shadow col-span-4 rounded-lg">
          <LibraryIcon fill="blue" strokeWidth={0.2} />
          <div className="h-[70px] p-2">
            <div>Number of Shops</div>
            <div>{isAdmin ? shopsList?.length : SellerShops?.length}</div>
          </div>
        </div>
        <div className="bg-white border border-gray-50 text-center shadow col-span-4 rounded-lg">
          <CollectionIcon strokeWidth={0.2} />
          <div className="h-[70px] p-2">
            <div>Number of Products</div>
            <div>{isAdmin ? productsList?.length : SellerProducts?.length}</div>
          </div>
        </div>

        {accessibleCheck() && (
          <div className="bg-white border border-gray-50 text-center shadow col-span-4 rounded-lg">
            <ArchiveIcon fill="orange" strokeWidth={0.2} />
            <div className="h-fit p-2">
              <div>Number of Orders</div>
              {isAdmin ? (
                <>
                  <div>{"(" + adminOrders?.total + ")"}</div>
                  <UiForex
                    form={adminOrders?.currency}
                    value={adminOrders?.cost}
                  />
                </>
              ) : (
                <>
                  <div>{"(" + SellerOrders?.total + ")"}</div>
                  <UiForex
                    form={SellerOrders?.currency}
                    value={SellerOrders?.cost}
                  />
                </>
              )}
            </div>
          </div>
        )}
      </div>

      {isAdmin && (
        <>
          {accessibleCheck() && (
            <>
              {/* subscriptions */}
              <div className="sm:grid sm:grid-flow-col sm:gap-3 mt-[50px]">
                <div className="bg-white border border-gray-50 text-center shadow col-span-4 rounded-lg">
                  <CurrencyDollarIcon
                    fill="green"
                    strokeWidth={0.2}
                    className="mx-auto sm:h-[15rem]"
                    height="10rem"
                  />
                  <div className="h-fit p-2">
                    <div>Number of Commissions</div>
                    <div>{"(" + Commissions?.total + ")"}</div>
                    <UiForex
                      form={Commissions?.currency}
                      value={Commissions?.cost}
                    />
                  </div>
                </div>
                <div className="bg-white border border-gray-50 text-center shadow col-span-4 rounded-lg">
                  <BriefcaseIcon fill="gold" strokeWidth={0.2} />
                  <div className="h-fit p-2">
                    <div>Number of Premier</div>
                    <div>{"(" + Premiers?.total + ")"}</div>
                    <UiForex form={Premiers?.currency} value={Premiers?.cost} />
                  </div>
                </div>
                <div className="bg-white border border-gray-50 text-center shadow col-span-4 rounded-lg">
                  <CreditCardIcon fill="purple" strokeWidth={0.2} />
                  <div className="h-fit p-2">
                    <div>Number of Adverts</div>
                    <div>{"(" + Adverts?.total + ")"}</div>
                    <UiForex form={Adverts?.currency} value={Adverts?.cost} />
                  </div>
                </div>
              </div>
            </>
          )}

          {/* users */}
          <div className="sm:grid sm:grid-flow-col sm:gap-3 mt-[50px]">
            <div className="bg-white border border-gray-50 text-center shadow col-span-4 rounded-lg">
              <DocumentIcon fill="skyblue" strokeWidth={0.2} />
              <div className="h-[70px] p-2">
                <div>Number of Admins</div>
                <div>{Admins?.length}</div>
              </div>
            </div>
            <div className="bg-white border border-gray-50 text-center shadow col-span-4 rounded-lg">
              <IdentificationIcon fill="lime" strokeWidth={0.2} />
              <div className="h-[70px] p-2">
                <div>Number of Sellers</div>
                <div>{Sellers?.length}</div>
              </div>
            </div>
            <div className="bg-white border border-gray-50 text-center shadow col-span-4 rounded-lg">
              <CashIcon fill="red" strokeWidth={0.2} />
              <div className="h-[70px] p-2">
                <div>Number of Buyers</div>
                <div>{Buyers?.length}</div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
export default Landing;

async function getList({ path, payload }: ActionProps | any) {
  let result = null;
  try {
    const headers: any = {
      "Content-Type": "application/json",
      Accept: "application/json",
      // "Authorization": `Bearer ${token}`
    };
    const response = await axios.post(`${baseURl + path}`, payload, {
      headers,
    });
    return (result = response.data);
  } catch (error) {
    // @ts-ignore
    return error.message;
  }
}
