import classNames from "classnames";
import React, { useState } from "react";

import { formSchema } from "../../constants/form-names";
import SearchBar from "../ui/search-bar";
import TableLayout from "../ui/table";
import { TagShortDecription } from "../ui/tag-short-decription";
import { useModal } from "../ui/modal/modal.context";
import Loader from "../ui/spinner";
import { usePermission } from "../hook/permission";
import { useSearchEngineFull } from "../hook/search-engine";
import { ErrorPage } from "./error";
import { useOrderStatus } from "../hook/use-orderStatus";
import ControlButton from "../ui/button/control-button";

const pageSettings = {
  pageName: "orderStatus",
  item: {
    counter: true,
  },
  schema: {
    form: "orderStatusForm",
    option: "orderStatusOptionForm",
    formKeys: formSchema
      .filter((v) => v.schema === "orderStatusForm")
      .map((v) => v.key),
    searchColumns: ["id", "name"],
    searchIndex: 6,
    formExtension: [],
  },
  explore: {
    isOpen: false,
    editor: 0,
    item: null,
    currentItemOriginalDetails: null,
  },
  control: {
    allowedControl: true,
    restrictions: [],
  },
};

function OrderStatus() {
  const isAdmin = usePermission(2);
  const { openModal } = useModal();
  const [notifier, setNotifier] = useState<boolean>(false);
  const searcher = useSearchEngineFull();
  const orderStatus = useOrderStatus();

  //preset calls
  const information = orderStatus,
    listInformation = searcher.info?.HLL ?? orderStatus.info?.HLL?.data;

  React.useEffect(() => {
    orderStatus.getOrderStatus({ page: 1 });
  }, []);

  //___list
  //regenerate list with columns' title
  const listed =
    typeof information.info === "object" &&
    information.info?.HLL?.data.length !== undefined
      ? listInformation.map((item) => {
          // console.log("original", item);
          const selectedDetail = filterInformation(item);
          let currentProperties = Object.entries(selectedDetail),
            columns = currentProperties.map((property) => {
              return {
                title:
                  property[0] === "is_active"
                    ? "Status"
                    : property[0].charAt(0).toUpperCase() +
                      property[0]?.replace(/[(_)(-)]/gi, " ").slice(1),
                dataIndex: property[0],
                key: property[0],
                render: (value, row) => (
                  <div
                    className={classNames(
                      "text-center mx-5 cursor-pointer text-[15px]",
                      {
                        "bg-green-800 rounded-full h-3 w-3":
                          property[0] === "is_active" && (value || value === 1),
                      },
                      {
                        "bg-red-800 rounded-full h-3 w-3":
                          property[0] === "is_active" &&
                          (!value || value === 0),
                      }
                    )}
                    onDoubleClick={() =>
                      openModal({ view: "ALERT", payload: value })
                    }
                  >
                    {property[0] !== "is_active" && value}
                  </div>
                ),
              };
            });
          return {
            ...selectedDetail,
            columns,
          };
        })
      : [];

  //searching
  const handleSearch = (searchTerm) => {
    searcher.findRelated({
      keyIndex: pageSettings.schema.searchIndex,
      value: searchTerm,
      targets: pageSettings.schema.searchColumns,
    });
  };

  React.useEffect(() => {
    if (notifier && information.error) {
      openModal({ view: "ALERT", payload: information.error });
      setTimeout(() => {
        setNotifier(!notifier);
      }, 1500);
    } else if (notifier && information.info?.query) {
      openModal({ view: "ALERT", payload: information.info?.query });
      setTimeout(() => {
        setNotifier(!notifier);
      }, 1500);
    }
  }, [information]);

  if (information.loading) {
    return <Loader />;
  }

  if (information.error) {
    return <ErrorPage />;
  }

  return (
    <div className="mt-5 md:mt-0 w-5/6">
      {/* tag */}
      <TagShortDecription
        title="Administrative"
        description="This is section is only visible to administrators"
        outerlayerStyle="md:col-span-1"
        innerBodyStyle="px-4 sm:px-0 my-10"
        innerHeaderStyle="text-lg font-medium leading-6 text-gray-900"
        innerlayerStyle="mt-1 text-sm text-gray-600"
      />

      {/* ssearch */}
      <div className="bg-white my-5 p-3 lg:p-5 md:p-10 focus:ring-indigo-500 shadow">
        <SearchBar searching={(v) => handleSearch(v)} />
        <ControlButton
          toggleCondition={false}
          authenticity={pageSettings.item.counter && isAdmin}
          totalCount={information.info?.HLL?.total}
        />
      </div>

      {/* list */}
      <TableLayout
        list={listed}
        pagination={{
          from: information.info?.HLL?.from,
          to: information.info?.HLL?.to,
          total: information.info?.HLL?.total,
          current: information.info?.HLL?.current_page,
          last: information.info?.HLL?.last_page,
          goto: (pageNumber: number) =>
            information.getOrderStatus({ page: pageNumber }),
        }}
      />
    </div>
  );
}
export default OrderStatus;

function filterInformation(info: any) {
  let selectedDetails = {
    ID: info?.id,
    name: info?.name,
  };
  return selectedDetails;
}
