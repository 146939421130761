import { SyntheticEvent } from "react";

export const submitter = (
  e:SyntheticEvent,
  extensionFormValues:any = null,
  explored:any = null,
  owner:any = null,
  images:any[] = [],
  ) => { 
    const target = e.target as typeof e.target & {
      ID: {
        value:number
      },
      name: {
        value:string
      },
      description: {
        value:string
      },
      street_name: {
        value:string
      },
      city: {
        value:string
      },
      state: {
        value:string
      },
      zip: {
        value:string
      },
      country: {
        value:string
      },
      category_id: {
        value:number
      },
      subcategory_id: {
        value:number
      },
      bank_name: {
        value:string
      },
      bank_country: {
        value:string
      },
      swift_code: {
        value:string
      },
      sort_code: {
        value:string
      },
      account_name: {
        value:string
      },
      account_number: {
        value:string
      },
      paypal_email: {
        value:string
      },
      currency: {
        value:string
      },
      price: {
        value:string
      },
      shop_id: {
        value:string
      },
      sale_price: {
        value:string
      },
      weight: {
        value:string
      },
      sku: {
        value:string
      },
      quantity: {
        value:string
      },
      in_stock: {
        value:string
      },
      premier: {
        value:string
      },
      product_type: {
        value:string
      },
      unit: {
        value:string
      },
      height: {
        value:string
      },
      width: {
        value:string
      },
      length: {
        value:string
      },
      image: {
        value:string
      },
      color: {
        value:string
      },
      size: {
        value:string
      },
      band_id: {
        value:string
      },
      brand_name: {
        value:string
      },
      from_email: {
        value:string
      },
      dispatch_status: {
        value:string
      },
      subject: {
        value:string
      },
      message: {
        value:string
      },
      serial: {
        value:string
      },
      commission_flat_rate: {
        value:string
      },
      commission_monthly: {
        value:string
      },
      commission_currency: {
        value:string
      },
      subcategory_name: {
        value:string
      },
      product_id: {
        value:string
      },
      type: {
        value:string
      },
      nature: {
        value:string
      },
      period: {
        value:string
      },
      total_clicks: {
        value:string
      },
      amount: {
        value:string
      },
      title: {
        value:string
      },
      qualification: {
        value:string
      },
      details: {
        value:string
      },
      expiry: {
        value:string
      },
      value: {
        value:string
      },
      code: {
        value:string
      },
      duration: {
        value:string
      },
      means: {
        value:string
      },
      note: {
        value:string
      },
      value_per_mile: {
        value:string
      },
      currency_symbol: {
        value:string
      },
      value_per_dollar: {
        value:number
      },
      city_details: {
        value:any
      },
      country_details: {
        value:any
      },
      state_details: {
        value:any
      },
    }

    let payload = {
      ...explored?.currentItemOriginalDetails,
      owner_id:owner?.id ?? null,
      name:target?.name?.value ?? explored?.currentItemOriginalDetails?.name,
      description:target?.description?.value ?? explored?.currentItemOriginalDetails?.description,
      address:{
          ...explored?.currentItemOriginalDetails?.address,
          street_name:target?.street_name?.value ?? explored?.currentItemOriginalDetails?.street_name, 
          zip:target?.zip?.value ?? explored?.currentItemOriginalDetails?.zip, 
          // city:target?.city?.value ?? explored?.currentItemOriginalDetails?.city, 
          // state:target?.state?.value ?? explored?.currentItemOriginalDetails?.state, 
          // country:target?.country?.value  ?? explored?.currentItemOriginalDetails?.country,
          // country_details:target?.country_details?.value  ?? explored?.currentItemOriginalDetails?.country_details,
          // state_details:target?.state_details?.value  ?? explored?.currentItemOriginalDetails?.state_details,
          // city_details:target?.city_details?.value  ?? explored?.currentItemOriginalDetails?.city_details,
          city: extensionFormValues.address?.city ?? target?.city?.value,
          city_details: extensionFormValues.address?.city_details ?? target?.city_details?.value,
          country: extensionFormValues.address?.country ?? target?.country?.value,
          country_details: extensionFormValues.address?.country_details ?? target?.country_details?.value,
          state: extensionFormValues.address?.state ?? target?.state?.value,
          state_details: extensionFormValues.address?.state_details ?? target?.state_details?.value,
      },
      category_id:extensionFormValues?.category?.category_id ?? explored?.currentItemOriginalDetails?.category_id,
      subcategory_id:extensionFormValues?.subcategory?.subcategory_id ?? explored?.currentItemOriginalDetails?.subcategory_id,
      bank:{ 
          ...explored?.currentItemOriginalDetails?.bank,
          bank_name:target?.bank_name?.value ?? explored?.currentItemOriginalDetails?.bank_name,
          bank_country:target?.bank_country?.value ?? explored?.currentItemOriginalDetails?.bank_country,
          swift_code:target?.swift_code?.value ?? explored?.currentItemOriginalDetails?.swift_code,
          sort_code:target?.sort_code?.value ?? explored?.currentItemOriginalDetails?.sort_code,
          account_name:target?.account_name?.value ?? explored?.currentItemOriginalDetails?.account_name, 
          account_number:target?.account_number?.value ?? explored?.currentItemOriginalDetails?.account_number, 
      },
      paypal:{ 
          ...explored?.currentItemOriginalDetails?.paypal,
          paypal_email:target?.paypal_email?.value ?? explored?.currentItemOriginalDetails?.paypal_email, 
          name_of_seller:target?.paypal_email?.value ? owner?.name : '', 
      },
      currency: extensionFormValues?.currency ?? explored?.currentItemOriginalDetails?.currency,
      price: target?.price?.value ?? 0,
      shop_id: extensionFormValues?.shop?.shop_id ?? explored?.currentItemOriginalDetails?.shop_id,
      sale_price: target?.sale_price?.value ?? 0,
      weight: target?.weight?.value ?? explored?.currentItemOriginalDetails?.weight,
      sku: target?.sku?.value ?? explored?.currentItemOriginalDetails?.sku,
      quantity: target?.quantity?.value ?? 1,
      in_stock: target?.in_stock?.value  ?? explored?.currentItemOriginalDetails?.in_stock,
      premier: target?.premier?.value ?? 'no',
      product_type: target?.product_type?.value ?? 'new',
      unit: target?.unit?.value ?? explored?.currentItemOriginalDetails?.unit,
      height: target?.height?.value ?? explored?.currentItemOriginalDetails?.height,
      width: target?.width?.value ?? explored?.currentItemOriginalDetails?.width,
      length: target?.length?.value ?? explored?.currentItemOriginalDetails?.length,
      image: images ?? explored?.currentItemOriginalDetails?.image,
      variety_option: {
        color: target?.color?.value ?? null,
        size: target?.size?.value ?? null,
      },
      band:{//note: "brand" unable to change type of typpoerror cause of it wild usage
        band_id: target?.band_id?.value ?? explored?.currentItemOriginalDetails?.band_id,
        band_name: target?.brand_name?.value ?? explored?.currentItemOriginalDetails?.band_name,
      },
      band_name: target?.brand_name?.value ?? explored?.currentItemOriginalDetails?.band_name,//extension
      from_email: target?.from_email?.value  ?? explored?.currentItemOriginalDetails?.from_email,
      dispatch_status: target?.dispatch_status?.value  ?? explored?.currentItemOriginalDetails?.dispatch_status,
      subject: target?.subject?.value  ?? explored?.currentItemOriginalDetails?.subject,
      message: target?.message?.value  ?? explored?.currentItemOriginalDetails?.message,
      serial: target?.serial?.value ?? null,
      commission_flat_rate: target?.commission_flat_rate?.value  ?? explored?.currentItemOriginalDetails?.commission_flat_rate,
      commission_monthly: target?.commission_monthly?.value  ?? explored?.currentItemOriginalDetails?.commission_monthly,
      commission_currency: extensionFormValues?.commission_currency ?? explored?.currentItemOriginalDetails?.commission_currency,
      subcategory_name: extensionFormValues?.subcategory_name ?? explored?.currentItemOriginalDetails?.subcategory_name,
      product_id: target?.product_id?.value ?? 0, 
      type: target?.type?.value  ?? explored?.currentItemOriginalDetails?.type,
      nature: target?.nature?.value  ?? explored?.currentItemOriginalDetails?.nature,
      period: target?.period?.value  ?? explored?.currentItemOriginalDetails?.period,
      total_clicks: target?.total_clicks?.value  ?? explored?.currentItemOriginalDetails?.total_clicks,
      amount: target?.amount?.value  ?? explored?.currentItemOriginalDetails?.amount,
      title: target?.title?.value  ?? explored?.currentItemOriginalDetails?.title,
      qualification: target?.qualification?.value  ?? explored?.currentItemOriginalDetails?.qualification,
      details: target?.details?.value  ?? explored?.currentItemOriginalDetails?.details,
      expiry: target?.expiry?.value  ?? explored?.currentItemOriginalDetails?.expiry,
      value: target?.value?.value  ?? explored?.currentItemOriginalDetails?.value,
      code: target?.code?.value  ?? explored?.currentItemOriginalDetails?.code,
      duration: target?.duration?.value ?? explored?.currentItemOriginalDetails?.duration,
      means: target?.means?.value  ?? explored?.currentItemOriginalDetails?.means,
      note: target?.note?.value  ?? explored?.currentItemOriginalDetails?.note,
      value_per_mile: target?.value_per_mile?.value  ?? explored?.currentItemOriginalDetails?.value_per_mile,
      currency_symbol: target?.currency_symbol?.value  ?? explored?.currentItemOriginalDetails?.currency_symbol,
      value_per_dollar: target?.value_per_dollar?.value  ?? explored?.currentItemOriginalDetails?.value_per_dollar,
    }

    return payload;
  }