import React from 'react'

import { PermissionFormValuesProps } from '../../types';

export function SubmitterPermission( pageSettings:any, permissionFormValues: PermissionFormValuesProps) {
    let isEmpty = Object.values(permissionFormValues).every(Boolean);
    if (isEmpty) {
        return;
    }

    if (pageSettings.pageName == "users") {       
    
        let payload = {
            abilities: permissionFormValues.ability === "Admin" ? [1,2,3] : [1]
        }
        return payload;
    }

    if(pageSettings.pageName === "orders"){
        return {
            status:permissionFormValues.state,
        };
    }

    if(pageSettings.pageName === "products"){
        return {
            type: "state",
            premier: permissionFormValues.premier,
            product_type: permissionFormValues.product_type,
            state: permissionFormValues.state,
        };
    }

    if(pageSettings.pageName === "subcriptions"){
        return {
            is_active:permissionFormValues.is_active
        };
    }
    
    return permissionFormValues;//incase of no special modification

}
