import React, { SyntheticEvent } from "react";
import { XIcon } from "@heroicons/react/outline";

type SearchBarProps = {
  searching: any;
  placeholder?: any;
};
function SearchBar({ searching, placeholder }: SearchBarProps) {
  const handleSearchHandler = (e: SyntheticEvent) => {
    e.preventDefault();

    const target = e.target as typeof e.target & {
      search: {
        value: string;
      };
    };
    const search = target.search.value.replace(/[^A-Za-z0-9\-_\s]/gi, "");

    if (search.length < 2) {
      return;
    }

    searching(search);
  };

  const clearHandler = () => {
    // @ts-ignore
    window.document.getElementById("search").value = "";

    setTimeout(() => {
      window.location.reload();
    }, 1500);
  };
  return (
    <>
      <form
        onSubmit={handleSearchHandler}
        className="flex items-top justify-between rounded-lg my-2 mx-3 lg:m-10 md:m-5 focus:ring-indigo-500 shadow-sm border border-gray-300"
      >
        <input
          type="text"
          id="search"
          name="search"
          placeholder={placeholder}
          className="w-3/4 block sm:text-sm border-none rounded-l-md focus:ring-gray-300"
        />
        <XIcon
          className="w-3 h-3 border-none bg-gray-300 inline-flex justify-center items-center m-3 cursor-pointer rounded-lg"
          onClick={() => clearHandler()}
        />
        <input
          type="submit"
          value="Search"
          className="cursor-pointer inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-r-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none"
        />
      </form>
    </>
  );
}
export default SearchBar;
