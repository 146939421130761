import classNames from "classnames";
import React, { SyntheticEvent, useState } from "react";

import { formSchema } from "../../constants/form-names";
import { PermissionForm } from "../ui/create-or-update.tsx";
import SearchBar from "../ui/search-bar";
import TableLayout from "../ui/table";
import { TagShortDecription } from "../ui/tag-short-decription";
import { useModal } from "../ui/modal/modal.context";
import Loader from "../ui/spinner";
import { usePermission } from "../hook/permission";
import { useSearchEngineFull } from "../hook/search-engine";
import { ErrorPage } from "./error";
import { usePersonal } from "../hook/user-detail";
import { SubmitterPermission } from "../hook/submitter-permission";
import ControlButton from "../ui/button/control-button";
import ActionButton from "../ui/button/action-button";
import {
  ExtensionFormValuesProps,
  PermissionFormValuesProps,
} from "../../types";
import { useOrder } from "../hook/use-order";
import { useConverter } from "../hook/currency-conversion";

const pageSettings = {
  pageName: "orders",
  item: {
    counter: true,
  },
  schema: {
    form: "orderForm",
    option: "orderOptionForm",
    formKeys: formSchema
      .filter((v) => v.schema === "orderForm")
      .map((v) => v.key),
    searchColumns: ["id", "created_at", "tracking_number"],
    searchIndex: 4,
    formExtension: [],
  },
  explore: {
    isOpen: false,
    editor: 0,
    item: null,
    currentItemOriginalDetails: null,
  },
  control: {
    allowedControl: true,
    restrictions: [],
  },
};

function Orders() {
  const { data } = usePersonal(true);
  const isAdmin = usePermission(2);
  const { openModal } = useModal();
  const [explored, explore] = useState<typeof pageSettings.explore | any>(
    pageSettings.explore
  );
  const [notifier, setNotifier] = useState<boolean>(false);
  const searcher = useSearchEngineFull();
  const orders = useOrder();
  const { iforex, UiForex } = useConverter();
  const [permissionFormValues, setpermissionFormValues] = React.useState<
    PermissionFormValuesProps | any
  >({
    is_active: false,
    ability: "",
    type: "",
    premier: "",
    product_type: "",
    state: "",
    id: null,
    dispatch_status: null,
  });

  //preset calls
  const information = orders,
    listInformation = searcher.info?.HLL ?? orders.info?.HLL?.data;

  React.useEffect(() => {
    orders.getOrder({ page: 1 });
  }, []);

  //___list
  //select on list
  const handleSelect = (item) => {
    const currentItemOriginalDetails = information.info?.HLL?.data.find(
      (v) => v.id === item.ID
    );

    //view order
    openModal({ view: "VIEW_ORDER", payload: currentItemOriginalDetails });

    return explore({
      isOpen: true,
      editor: 2,
      item,
      currentItemOriginalDetails,
    });
  };
  //regenerate list with columns' title
  const listed =
    typeof information.info === "object" &&
    information.info?.HLL?.data.length !== undefined
      ? listInformation.map((item) => {
          item = {
            ...item,
            total: iforex({ from: item?.currency, value: item?.total }),
            paid_total: iforex({
              from: item?.currency,
              value: item?.paid_total,
            }),
            delivery_fee: iforex({
              from: item?.currency,
              value: item?.delivery_fee,
            }),
          };
          // console.log("original", item);
          const selectedDetail = filterInformation(item);
          let currentProperties = Object.entries(
              selectedDetail.selectedDetails
            ),
            columns = currentProperties.map((property) => {
              return {
                title:
                  property[0].charAt(0).toUpperCase() +
                  property[0]?.replace(/[(_)(-)]/gi, " ").slice(1),
                dataIndex: property[0],
                key: property[0],
                render: (value, row) => (
                  <div
                    className={classNames(
                      "text-justify mx-5 cursor-pointer text-[15px]",
                      { "w-[200px]": property[0] === "shipping_address" }
                    )}
                    onClick={() => handleSelect(row)}
                    onDoubleClick={() =>
                      openModal({ view: "ALERT", payload: value })
                    }
                  >
                    {/* {value} */}
                    {property[0] !== "paid_total" && property[0] !== "total" ? (
                      value
                    ) : (
                      <UiForex from={selectedDetail.currency} value={value} />
                    )}
                  </div>
                ),
              };
            });
          return {
            ...selectedDetail.selectedDetails,
            columns,
          };
        })
      : [];

  //searching
  const handleSearch = (searchTerm) => {
    searcher.findRelated({
      keyIndex: pageSettings.schema.searchIndex,
      value: searchTerm,
      targets: pageSettings.schema.searchColumns,
    });
  };

  // acitvation and deactivation
  const handleActivation = () => {
    let inputValue = SubmitterPermission(pageSettings, permissionFormValues),
      payload = {
        ...inputValue,
        id: explored?.currentItemOriginalDetails?.id,
      };
    console.log("activation or deactivation", payload);
    setNotifier(!notifier);
    information.deactivateOrder(payload);
  };

  React.useEffect(() => {
    if (notifier && information.error) {
      openModal({ view: "ALERT", payload: information.error });
      setTimeout(() => {
        setNotifier(!notifier);
      }, 1500);
    } else if (notifier && information.info?.query) {
      openModal({ view: "ALERT", payload: information.info?.query });
      setTimeout(() => {
        setNotifier(!notifier);
      }, 1500);
    }
  }, [information]);

  if (information.loading) {
    return <Loader />;
  }

  if (information.error) {
    return <ErrorPage />;
  }

  return (
    <div className="mt-5 md:mt-0 w-5/6">
      {/* tag */}
      <TagShortDecription
        title="Administrative"
        description="This is section is only visible to administrators"
        outerlayerStyle="md:col-span-1"
        innerBodyStyle="px-4 sm:px-0 my-10"
        innerHeaderStyle="text-lg font-medium leading-6 text-gray-900"
        innerlayerStyle="mt-1 text-sm text-gray-600"
      />

      {/* ssearch */}
      <div className="bg-white my-5 p-3 lg:p-5 md:p-10 focus:ring-indigo-500 shadow">
        <SearchBar searching={(v) => handleSearch(v)} placeholder="Search" />
        <ControlButton
          onClick={() => explore({ isOpen: !explored.isOpen, editor: 2 })}
          toggleCondition={explored.isOpen}
          authenticity={pageSettings.item.counter && isAdmin}
          totalCount={information.info?.HLL?.total}
        />
      </div>

      {/* edit section */}
      {explored.isOpen && (
        <div className=" border border-white shadow">
          <ActionButton
            onClickControl={() =>
              explore({ ...explored, isOpen: true, editor: 2 })
            }
            styliCondition={explored.editor}
            editorButtonTitle="Review"
            settings={{
              control: true,
              editor: false,
            }}
          />

          <div className="lg:grid lg:grid-flow-col lg:gap-3">
            {explored.editor === 2 && (
              <PermissionForm
                schema={formSchema}
                item={explored.item}
                schemaSelect={pageSettings.schema.option}
                submit={(e: SyntheticEvent) => {
                  e.preventDefault();
                  handleActivation();
                }}
                styli={{
                  classNameGroupedList: "flex-col",
                  classNameGrouped: "justify-start",
                }}
                onChange={(v) => {
                  let state = alternator(v?.title);
                  console.log("state", state, v?.title);
                  setpermissionFormValues({
                    ...permissionFormValues,
                    state,
                  });
                }}
                defaultChecked={explored?.currentItemOriginalDetails?.is_active}
                colorization={false}
              />
            )}
          </div>
        </div>
      )}

      {/* list */}
      <TableLayout
        list={listed}
        pagination={{
          from: information.info?.HLL?.from,
          to: information.info?.HLL?.to,
          total: information.info?.HLL?.total,
          current: information.info?.HLL?.current_page,
          last: information.info?.HLL?.last_page,
          goto: (pageNumber: number) =>
            information.getOrder({ page: pageNumber }),
        }}
      />
    </div>
  );
}
export default Orders;

function filterInformation(info: any) {
  let selectedDetails = {
    ID: info?.id,
    parent_id: info?.parent_id,
    payment_gateway: info?.payment_gateway,
    tracking_number: info?.tracking_number,
    number_of_items: info?.number_of_items,
    status: info?.status?.name,
    paid_total: info?.paid_total_fxws,
    total: info?.total_fxws,
    shipping_address:
      info?.shipping_address?.street_name +
      ", " +
      info?.shipping_address?.city +
      ", " +
      info?.shipping_address?.state +
      ", " +
      info?.shipping_address?.country +
      ", " +
      info?.shipping_address?.zip,
    logistics: info?.logistics,
    delivery_fee: info?.delivery_fee,
  };
  return {
    selectedDetails,
    currency: info?.currency,
  };
}

function alternator(tag: string) {
  switch (tag) {
    case "New Order":
      return 1;
    case "Order Confirmed":
      return 2;
    case "Order Shipped":
      return 3;
    case "Order in Transit":
      return 4;
    case "Order Delivered":
      return 5;
    case "Order Cancelled":
      return 6;
    default:
      return;
  }
}
