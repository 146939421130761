import classNames from "classnames";
import React from "react";
import { useCategory } from "../../hook/use-category";
import { useExchanges } from "../../hook/use-exchanges";
import { CheckBoxWithOption } from "../check-box";
import { FormLayout } from "../form-layout";
import { InputBox, FileBox, TextAreaBox } from "../input-box";
import {
  CustomSelect,
  CustomSelectProps,
  SelectCountry,
} from "../selection-box";

type CreateOrUpdateFormProps = {
  item: any;
  schema: any[];
  schemaSelect: string;
  submit: any;
  submitImage?: any;
  selectImage?: any;
  clear?: any;
  enableCountry?: boolean;
  enableState?: boolean;
  enableCity?: boolean;
  autoTitle?: boolean;
  defaultCountry?: string;
  defaultState?: string;
  defaultCity?: string;
  countrySelected?: any;
  stateSelected?: any;
  citySelected?: any;
  onChange?: any;
  styli?: {
    classNameInput?: string;
    classNameLabel?: string;
    classNameGrouped?: string;
    classForm?: string;
    ImageLayout?: string;
    classFormGrid?: string;
    topGrid?: string;
  };
  category?: CustomSelectProps;
  subcategory?: CustomSelectProps;
  brand?: CustomSelectProps;
  subscriptionType?: CustomSelectProps;
  subscriptionPeriod?: CustomSelectProps;
  shops?: CustomSelectProps;
  currency?: CustomSelectProps;
  typeTag?: CustomSelectProps;
  required?: boolean;
  setClearButtonTitle?: boolean;
  setSubmitButtonTitle?: boolean;
  exemptPage?: string;
};

let stylii = {
  classNameInput:
    "mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md",
  classNameLabel: "block text-sm font-medium text-gray-700 capitalize",
  classNameGrouped: "col-span-6",
  classForm: "w-full max-h-screen overflow-y-auto",
  classFormGrid: "",
  ImageLayout: "w-[230px] sm:w-[300px]",
  topGrid: "col-span-9 lg:w-full",
};
export function CreateOrUpdateForm({
  item,
  submit,
  schema = [],
  styli,
  clear,
  schemaSelect = "",
  submitImage,
  selectImage,
  enableCountry,
  enableState,
  enableCity,
  autoTitle,
  defaultCountry,
  defaultState,
  defaultCity,
  countrySelected,
  stateSelected,
  citySelected,
  category,
  subcategory,
  brand,
  subscriptionType,
  subscriptionPeriod,
  currency,
  onChange,
  shops,
  required,
  setClearButtonTitle = true,
  exemptPage = "",
  typeTag,
  setSubmitButtonTitle,
}: CreateOrUpdateFormProps) {
  const Category = useCategory();
  const currencies = useExchanges();
  const [currentItem, setCurrentItem] = React.useState<any>(null);
  const [subcategories, setsubcategorires] = React.useState<any>(null);

  // preset calls
  const CategoryList = Category.info?.HLL?.data,
    Currencies = currencies.info?.HLL;

  React.useEffect(() => {
    setCurrentItem(item);
    exemptPage !== "category" && Category.getCategory({ page: 1 });
    exemptPage !== "currency" && currencies.getExchanges({ page: 1 });
  }, [item]);

  return (
    <FormLayout
      onsubmit={submit}
      setSubmitButtonTitle={setSubmitButtonTitle}
      setClearButtonTitle={setClearButtonTitle}
      onClear={clear}
      topGrid={stylii?.topGrid}
      layoutStyli={classNames(styli?.classForm, stylii?.classForm)}
      gridLayout={classNames(styli?.classFormGrid, stylii?.classFormGrid)}
    >
      {schema
        .filter((v) => v.schema === schemaSelect)
        .map((value, index) => {
          let inputItem: any =
            currentItem &&
            Object.entries(currentItem).find((v) => v[0] === value.key);

          if (value.key === "is_active") {
            return <></>;
          }

          if (value.key === "image") {
            return (
              <FileBox
                key={index}
                layout={stylii?.ImageLayout}
                onSelect={selectImage}
                onSubmit={submitImage}
                fileType="images"
                shortDescriptionOnFile="JPG, PNG."
              />
            );
          }

          if (value.key === "description" || value.key === "message") {
            return (
              <TextAreaBox
                key={index}
                type="text"
                name={value.key}
                // required={value.required}
                labelTitle={value.value}
                placeholder={value.placeholderTexT}
                numberOfRows={5}
                classNameInput={classNames(
                  stylii.classNameInput,
                  styli?.classNameInput
                )}
                classNameLabel={classNames(
                  stylii.classNameLabel,
                  styli?.classNameLabel
                )}
                classNameGrouped={classNames(
                  stylii.classNameGrouped,
                  styli?.classNameGrouped
                )}
                defaultValue={inputItem ? inputItem[1] : ""}
              />
            );
          }

          if (value.key === "country") {
            return (
              <SelectCountry
                enableCountry={enableCountry}
                enableState={enableState}
                enableCity={enableCity}
                autoTitle={autoTitle}
                countrySelected={countrySelected}
                stateSelected={stateSelected}
                citySelected={citySelected}
                defaultCountry={defaultCountry}
                defaultState={defaultState}
                defaultCity={defaultCity}
              />
            );
          }

          if (value.key === "category") {
            return (
              <CustomSelect
                onPick={(v) => {
                  category?.onPick(v);
                  setsubcategorires(v?.subcategories);
                }}
                defaultValue={category?.defaultValue}
                title={category?.title}
                schema={category?.schema}
                list={CategoryList ?? []}
                displayValuePropertyIndex={category?.displayValuePropertyIndex}
                listValueType={category?.listValueType}
                required={category?.required}
                exemptPage={exemptPage}
                defaultValueOver={category?.defaultValueOver}
                enableDefaultValueOver={category?.enableDefaultValueOver}
              />
            );
          }
          if (value.key === "subcategory") {
            return (
              <CustomSelect
                onPick={subcategory?.onPick}
                defaultValue={subcategory?.defaultValue}
                title={subcategory?.title}
                schema={subcategory?.schema}
                list={subcategories ?? []}
                displayValuePropertyIndex={
                  subcategory?.displayValuePropertyIndex
                }
                listValueType={subcategory?.listValueType}
                required={subcategory?.required}
                exemptPage={exemptPage}
              />
            );
          }
          if (value.key === "brand") {
            return (
              <CustomSelect
                onPick={brand?.onPick}
                defaultValue={brand?.defaultValue}
                title={brand?.title}
                schema={brand?.schema}
                list={brand?.list}
                displayValuePropertyIndex={brand?.displayValuePropertyIndex}
                listValueType={brand?.listValueType}
                required={brand?.required}
              />
            );
          }
          if (value.key === "type") {
            return (
              <CustomSelect
                onPick={subscriptionType?.onPick}
                defaultValue={subscriptionType?.defaultValue}
                title={subscriptionType?.title}
                schema={subscriptionType?.schema}
                list={subscriptionType?.list}
                displayValuePropertyIndex={
                  subscriptionType?.displayValuePropertyIndex
                }
                listValueType={subscriptionType?.listValueType}
                required={subscriptionType?.required}
              />
            );
          }
          if (value.key === "period") {
            return (
              <CustomSelect
                onPick={subscriptionPeriod?.onPick}
                defaultValue={subscriptionPeriod?.defaultValue}
                title={subscriptionPeriod?.title}
                schema={subscriptionPeriod?.schema}
                list={subscriptionPeriod?.list}
                displayValuePropertyIndex={
                  subscriptionPeriod?.displayValuePropertyIndex
                }
                listValueType={subscriptionPeriod?.listValueType}
                required={subscriptionPeriod?.required}
              />
            );
          }
          if (value.key === "currency" && exemptPage !== "currency") {
            return (
              <CustomSelect
                onPick={currency?.onPick}
                defaultValue={currency?.defaultValue}
                title={currency?.title}
                schema={currency?.schema}
                list={Currencies ?? []}
                displayValuePropertyIndex={currency?.displayValuePropertyIndex}
                listValueType={currency?.listValueType}
                required={currency?.required}
                defaultValueOver={currency?.defaultValueOver}
                enableDefaultValueOver={currency?.enableDefaultValueOver}
              />
            );
          }
          if (value.key === "shop") {
            return (
              <CustomSelect
                onPick={shops?.onPick}
                defaultValue={shops?.defaultValue}
                title={shops?.title}
                schema={shops?.schema}
                list={shops?.list}
                displayValuePropertyIndex={shops?.displayValuePropertyIndex}
                listValueType={shops?.listValueType}
                required={shops?.required}
              />
            );
          }
          if (value.key === "typeTag") {
            return (
              <CustomSelect
                onPick={typeTag?.onPick}
                defaultValue={typeTag?.defaultValue}
                title={typeTag?.title}
                schema={typeTag?.schema}
                list={typeTag?.list}
                displayValuePropertyIndex={typeTag?.displayValuePropertyIndex}
                listValueType={typeTag?.listValueType}
                required={typeTag?.required}
              />
            );
          }

          return (
            <InputBox
              key={index}
              type="text"
              name={value.key}
              autoComplete={value.value}
              classNameInput={classNames(
                stylii.classNameInput,
                styli?.classNameInput
              )}
              classNameLabel={classNames(
                stylii.classNameLabel,
                styli?.classNameLabel
              )}
              classNameGrouped={classNames(
                stylii.classNameGrouped,
                styli?.classNameGrouped
              )}
              placeholder={value.placeholderTexT}
              requiredByIndicator={value.required}
              labelTitle={value.value}
              defaultValue={inputItem ? inputItem[1] : ""}
              onChange={(e) => onChange({ index, value: e.target.value })}
            />
          );
        })}
    </FormLayout>
  );
}

type PermissionFormProps = {
  item: any;
  schema: any;
  submit: any;
  onChange?: any;
  schemaSelect: string;
  clearButtonTitle?: string;
  onClear?: any;
  setClearButtonTitle?: boolean;
  clearButtonStyli?: string;
  defaultChecked?: boolean | string | number | string[];
  colorization?: boolean;
  setSubmitButtonTitle?: boolean;
  styli?: {
    classNameCheck?: string;
    classNameLabel?: string;
    classNameGrouped?: string;
    classNameGroupedList?: string;
    classNameGroupedTopLayer?: string;
    classFormGrid?: string;
    topGrid?: string;
  };
};

let stylix = {
  classForm: "mt-5 sm:m-5",
  classFormGrid: "grid-cols-none",
  classNameGroupedTopLayer: "flex justify-between items-center",
  classNameGroupedList: "flex justify-center items-center",
  classNameGrouped: "flex justify-center items-center",
  classNameCheck:
    "focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded m-5",
  classNameLabel: "text-black",
  topGrid: "lg:w-fit",
};

export function PermissionForm({
  item,
  schema = [],
  submit,
  styli,
  schemaSelect,
  onChange,
  clearButtonTitle,
  onClear,
  setClearButtonTitle,
  clearButtonStyli,
  defaultChecked,
  colorization,
  setSubmitButtonTitle,
}: PermissionFormProps) {
  return (
    <FormLayout
      onsubmit={submit}
      clearButtonStyli={clearButtonStyli}
      clearButtonTitle={clearButtonTitle}
      onClear={onClear}
      setClearButtonTitle={setClearButtonTitle}
      setSubmitButtonTitle={setSubmitButtonTitle}
      topGrid={stylix?.topGrid}
      layoutStyli={classNames(stylix?.classForm, stylix?.classForm)}
      gridLayout={classNames(stylix?.classFormGrid, stylix?.classFormGrid)}
    >
      {schema
        .filter((v) => v.schema === schemaSelect)
        .map((value, index) => (
          <CheckBoxWithOption
            key={index}
            onChange={onChange}
            type="radio"
            name={value.key}
            labelTitle={value.value}
            classNameCheck={classNames(
              stylix?.classNameCheck,
              styli?.classNameCheck
            )}
            classNameLabel={classNames(
              stylix?.classNameLabel,
              styli?.classNameLabel
            )}
            classNameGrouped={classNames(
              stylix?.classNameGrouped,
              styli?.classNameGrouped
            )}
            classNameGroupedList={classNames(
              stylix?.classNameGroupedList,
              styli?.classNameGroupedList
            )}
            classNameGroupedTopLayer={classNames(
              stylix?.classNameGroupedTopLayer,
              styli?.classNameGroupedTopLayer
            )}
            useDisplayComponent={false}
            list={value.options}
            defaultChecked={defaultChecked}
            colorization={colorization}
          />
        ))}
    </FormLayout>
  );
}
