const ROUTES = {
  LOGIN: "/login",
  REGISTER: "/register",
  RESET: "/forgot-password",

  // HOME:"/",
  PACKAGES: "/packages",
  ACCOUNT: "/account",
  CHECKOUT: "/checkout",
  SUBCATEGORY: "/subcategory",
  SUCCESS: "/success",
  CANCEL: "/cancel",
  SEARCH: "/search",

  DASHBOARD: "/",
  ORDERS: "/orders",
  MY_ORDERS: "/my-orders",
  SUPPORT: "/support",
  BRANDS: "/brands",
  SHOPS: "/shops",
  MY_SHOPS: "/my-shops",
  CATEGORY: "/category",
  PRODUCTS: "/products",
  MY_PRODUCTS: "/my-products",
  TEMPLATES: "/mail-templates",
  SUBSCRIPTION: "/subscriptions",
  USERS: "/users",
  SUPPORTS: "/support",
  SUBSCRIPTION_PLAN: "/subscription-plans",
  JOB: "/job-posts",
  APPLICANTS: "/job-applicants",
  ORDER_STATUS: "/order-status",
  EXCHANGES: "/Currencies-exchange",
  SHIPPING: "/shipping-classes",
  MILES: "/mile-prices",
  BANK: "/bank",
  PAYPAL: "/paypal",
  DISTANCE: "/custom-distances",
  DELETION: "/deletion-requests",
  CHATLOGS: "/chat-logs",
};
export default ROUTES;
