import classNames from "classnames";
import React, { SyntheticEvent, useState } from "react";

import { formSchema } from "../../constants/form-names";
import { PermissionForm } from "../ui/create-or-update.tsx";
import SearchBar from "../ui/search-bar";
import TableLayout from "../ui/table";
import { TagShortDecription } from "../ui/tag-short-decription";
import { useModal } from "../ui/modal/modal.context";
import Loader from "../ui/spinner";
import { usePermission } from "../hook/permission";
import { useSearchEngineFull } from "../hook/search-engine";
import { ErrorPage } from "./error";
import { useSubcription } from "../hook/use-subcription";
import { usePersonal } from "../hook/user-detail";
import { SubmitterPermission } from "../hook/submitter-permission";
import ControlButton from "../ui/button/control-button";
import ActionButton from "../ui/button/action-button";
import {
  ExtensionFormValuesProps,
  PermissionFormValuesProps,
} from "../../types";

const pageSettings = {
  pageName: "subcriptions",
  item: {
    counter: true,
  },
  schema: {
    form: "subscriptionForm",
    option: "subscriptionOptionForm",
    formKeys: formSchema
      .filter((v) => v.schema === "subscriptionForm")
      .map((v) => v.key),
    searchColumns: ["id", "type", "nature", "period"],
    searchIndex: 12,
    formExtension: [],
  },
  explore: {
    isOpen: false,
    editor: 0,
    item: null,
    currentItemOriginalDetails: null,
  },
  control: {
    allowedControl: true,
    restrictions: [],
  },
};

function Subscriptions() {
  const { data } = usePersonal(true);
  const isAdmin = usePermission(2);
  const { openModal } = useModal();
  const [explored, explore] = useState<typeof pageSettings.explore | any>(
    pageSettings.explore
  );
  const [notifier, setNotifier] = useState<boolean>(false);
  const searcher = useSearchEngineFull();
  const subscriptions = useSubcription();
  const [permissionFormValues, setpermissionFormValues] =
    React.useState<PermissionFormValuesProps>({
      is_active: false,
      ability: "",
      type: "",
      premier: "",
      product_type: "",
      state: "",
      id: null,
    });
  const [extensionFormValues, setextensionFormValues] =
    React.useState<ExtensionFormValuesProps>({
      address: null,
      category: null,
      subcategory: null,
      brand: null,
      subpscriptionPlanType: null,
      subpscriptionPlanPeriod: null,
      shop: null,
      currency: null,
    });

  //preset calls
  let owner: any = data;
  const information = subscriptions,
    listInformation = searcher.info?.HLL ?? subscriptions.info?.HLL?.data;

  React.useEffect(() => {
    subscriptions.getSubcription({ page: 1 });
  }, []);

  //___list
  //select on list
  const handleSelect = (item) => {
    const currentItemOriginalDetails = information.info?.HLL?.data.find(
      (v) => v.id === item.ID
    );
    return explore({
      isOpen: true,
      editor: 2,
      item,
      currentItemOriginalDetails,
    });
  };
  //regenerate list with columns' title
  const listed =
    typeof information.info === "object" &&
    information.info?.HLL?.data.length !== undefined
      ? listInformation.map((item) => {
          // console.log("original", item);
          const selectedDetail = filterInformation(item);
          let currentProperties = Object.entries(selectedDetail),
            columns = currentProperties.map((property) => {
              return {
                title:
                  property[0] === "is_active"
                    ? "Status"
                    : property[0].charAt(0).toUpperCase() +
                      property[0]?.replace(/[(_)(-)]/gi, " ").slice(1),
                dataIndex: property[0],
                key: property[0],
                render: (value, row) => (
                  <div
                    className={classNames(
                      "text-justify mx-5 cursor-pointer text-[15px]",
                      {
                        "bg-green-800 rounded-full h-3 w-3":
                          property[0] === "is_active" && (value || value === 1),
                      },
                      {
                        "bg-red-800 rounded-full h-3 w-3":
                          property[0] === "is_active" &&
                          (!value || value === 0),
                      }
                    )}
                    onClick={() => handleSelect(row)}
                    onDoubleClick={() =>
                      openModal({ view: "ALERT", payload: value })
                    }
                  >
                    {property[0] !== "is_active" && value}
                  </div>
                ),
              };
            });
          return {
            ...selectedDetail,
            columns,
          };
        })
      : [];

  //searching
  const handleSearch = (searchTerm) => {
    searcher.findRelated({
      keyIndex: pageSettings.schema.searchIndex,
      value: searchTerm,
      targets: pageSettings.schema.searchColumns,
    });
  };

  // acitvation and deactivation
  const handleActivation = () => {
    let inputValue = SubmitterPermission(pageSettings, permissionFormValues),
      payload = {
        ...explored?.currentItemOriginalDetails,
        ...inputValue,
      };
    console.log("activation or deactivation", payload);
    setNotifier(!notifier);
    information.deactivateSubcription(payload);
  };

  React.useEffect(() => {
    if (notifier && information.error) {
      openModal({ view: "ALERT", payload: information.error });
      setTimeout(() => {
        setNotifier(!notifier);
      }, 1500);
    } else if (notifier && information.info?.query) {
      openModal({ view: "ALERT", payload: information.info?.query });
      setTimeout(() => {
        setNotifier(!notifier);
      }, 1500);
    }
  }, [information]);

  if (information.loading) {
    return <Loader />;
  }

  if (information.error) {
    return <ErrorPage />;
  }

  return (
    <div className="mt-5 md:mt-0 w-5/6">
      {/* tag */}
      <TagShortDecription
        title="Administrative"
        description="This is section is only visible to administrators"
        outerlayerStyle="md:col-span-1"
        innerBodyStyle="px-4 sm:px-0 my-10"
        innerHeaderStyle="text-lg font-medium leading-6 text-gray-900"
        innerlayerStyle="mt-1 text-sm text-gray-600"
      />

      {/* ssearch */}
      <div className="bg-white my-5 p-3 lg:p-5 md:p-10 focus:ring-indigo-500 shadow">
        <SearchBar searching={(v) => handleSearch(v)} />
        <ControlButton
          onClick={() => explore({ isOpen: !explored.isOpen, editor: 2 })}
          toggleCondition={explored.isOpen}
          authenticity={pageSettings.item.counter && isAdmin}
          totalCount={information.info?.HLL?.total}
        />
      </div>

      {/* edit section */}
      {explored.isOpen && (
        <div className=" border border-white shadow">
          <ActionButton
            onClickEditor={() => explore({ isOpen: true, editor: 2 })}
            onClickControl={() =>
              explore({ ...explored, isOpen: true, editor: 2 })
            }
            styliCondition={explored.editor}
            settings={{
              control: true,
              editor: false,
            }}
          />

          <div className="lg:grid lg:grid-flow-col lg:gap-3">
            {explored.editor === 2 && (
              <PermissionForm
                schema={formSchema}
                item={explored.item}
                schemaSelect={pageSettings.schema.option}
                submit={(e: SyntheticEvent) => {
                  e.preventDefault();
                  handleActivation();
                }}
                onChange={(v) =>
                  setpermissionFormValues({
                    ...permissionFormValues,
                    is_active: v?.title === "Active" ? false : true,
                  })
                }
                defaultChecked={explored?.currentItemOriginalDetails?.is_active}
              />
            )}
          </div>
        </div>
      )}

      {/* list */}
      <TableLayout
        list={listed}
        pagination={{
          from: information.info?.HLL?.from,
          to: information.info?.HLL?.to,
          total: information.info?.HLL?.total,
          current: information.info?.HLL?.current_page,
          last: information.info?.HLL?.last_page,
          goto: (pageNumber: number) =>
            information.getSubcription({ page: pageNumber }),
        }}
      />
    </div>
  );
}
export default Subscriptions;

function filterInformation(info: any) {
  let selectedDetails = {
    end_date: info?.end_date,
    payment_gateway: info?.payment_gateway,
    shop_id: info?.shop_id === 0 ? "" : info?.shop_id,
    is_active: info?.is_active,
    ID: info?.id,
    type: info?.type,
    nature: info?.nature,
    period: info?.period,
    unit: info?.unit,
    total_clicks: info?.total_clicks,
    amount: info?.amount,
    currency: info?.currency,
    // payment_gateway: info?.
  };
  return selectedDetails;
}
