import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { initialState, mileSelector, mileAction } from "../../redux/reducers/mile-reducer";

type UseMileProps = {
    createOrUpdateMile:(payload:any) => any 
    getMile:(payload:any) => any 
    deactivateMile:(payload:any) => any 
    deleteMile:(payload:any) => any

} & typeof initialState

export const useMile = ():UseMileProps => {
    const dispatch = useDispatch();
    const state = useSelector(mileSelector);


    const createOrUpdateMile = (payload:any) => dispatch<any>(mileAction({path:"/mile", payload}));
    const getMile = (payload:any) => dispatch<any>(mileAction({path:"/miles", payload}));
    const deactivateMile = (payload:any) => dispatch<any>(mileAction({path:"/mile/status", payload}));
    const deleteMile = (payload:any) => dispatch<any>(mileAction({path:"/mile/status", payload,}));

    return {
        ...state,
        createOrUpdateMile, 
        getMile, 
        deactivateMile, 
        deleteMile
    }
}