import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { shopAction, shopSelector } from "../../redux/reducers/shop-reducer";
import { initialState } from "../../redux/reducers/shop-reducer";

type UseShopProps = {
    createOrUpdateShop:(payload:any) => any 
    getShop:(payload:any) => any 
    getShopSimple:(payload:any) => any 
    deactivateShop:(payload:any) => any 
    deleteShop:(payload:any) => any

} & typeof initialState

export const useShop = ():UseShopProps => {
    const dispatch = useDispatch();
    const state = useSelector(shopSelector);


    const createOrUpdateShop = (payload:any) => dispatch<any>(shopAction({path:"/shop", payload}));
    const getShop = (payload:any) => dispatch<any>(shopAction({path:"/shops", payload}));
    const getShopSimple = (payload:any) => dispatch<any>(shopAction({path:"/shopz", payload}));
    const deactivateShop = (payload:any) => dispatch<any>(shopAction({path:"/shop/status", payload}));
    const deleteShop = (payload:any) => dispatch<any>(shopAction({path:"/shop/status", payload,}));

    return {
        ...state,
        createOrUpdateShop, 
        getShop, 
        getShopSimple, 
        deactivateShop, 
        deleteShop
    }
}