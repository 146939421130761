import React, { BrowserRouter, Routes, Route } from "react-router-dom";
import { ProtectedRoute } from "./components/auth/protect-route";

import Landing from "./components/page/landing";
import Login from "./components/auth/login";
import Register from "./components/auth/register";
import ForgotPassword from "./components/auth/forgot-password";
import Header from "./components/header";
import ROUTES from "./constants/routes-list";
import { NotFound } from "./components/page/error";
import SidebarNavigaton from "./components/header/sidebar-navigation";
import useToken from "./components/hook/token";
import Shops from "./components/page/shops";
import MyShops from "./components/page/my-shops";
import Products from "./components/page/products";
import Orders from "./components/page/orders";
import MyProducts from "./components/page/my-products";
import Templates from "./components/page/templates";
import Users from "./components/page/users";
import Categories from "./components/page/categories";
import Brands from "./components/page/brands";
import Subscriptions from "./components/page/subscriptions";
import JobPosts from "./components/page/jobs";
import OrderStatus from "./components/page/order-status";
import Exchanges from "./components/page/exchange";
import ShippingClass from "./components/page/shipping-classes";
import Miles from "./components/page/mile-prices";
import SubcriptionPlans from "./components/page/subscription-plans";
import MyOrders from "./components/page/my-orders";
import Supports from "./components/page/supports";
import Applicants from "./components/page/job-applicants";
import Distances from "./components/page/distances";
import Deletions from "./components/page/deletions";
import ChatLogMessages from "./components/page/chat-log-messages";

const App = () => {
  const [token] = useToken();
  return (
    <BrowserRouter>
      <Header />
      {!Boolean(token) ? (
        <>
          <Routes>
            <Route index element={<Login />} />
            <Route path={ROUTES.LOGIN} element={<Login />} />
            <Route path={ROUTES.REGISTER} element={<Register />} />
            <Route path={ROUTES.RESET} element={<ForgotPassword />} />
          </Routes>
        </>
      ) : (
        <SidebarNavigaton>
          <Routes>
            <Route element={<ProtectedRoute isAllowed={Boolean(token)} />}>
              <Route index element={<Landing />} />
              <Route path={ROUTES.DASHBOARD} element={<Landing />} />
              <Route path={ROUTES.CATEGORY} element={<Categories />} />
              <Route path={ROUTES.BRANDS} element={<Brands />} />
              <Route path={ROUTES.SHOPS} element={<Shops />} />
              <Route path={ROUTES.ORDERS} element={<Orders />} />
              <Route path={ROUTES.MY_ORDERS} element={<MyOrders />} />
              <Route path={ROUTES.MY_SHOPS} element={<MyShops />} />
              <Route path={ROUTES.PRODUCTS} element={<Products />} />
              <Route path={ROUTES.MY_PRODUCTS} element={<MyProducts />} />
              <Route path={ROUTES.TEMPLATES} element={<Templates />} />
              <Route path={ROUTES.SUBSCRIPTION} element={<Subscriptions />} />
              <Route path={ROUTES.USERS} element={<Users />} />
              <Route path={ROUTES.SUPPORTS} element={<Supports />} />
              <Route
                path={ROUTES.SUBSCRIPTION_PLAN}
                element={<SubcriptionPlans />}
              />
              <Route path={ROUTES.JOB} element={<JobPosts />} />
              <Route path={ROUTES.APPLICANTS} element={<Applicants />} />
              <Route path={ROUTES.ORDER_STATUS} element={<OrderStatus />} />
              <Route path={ROUTES.EXCHANGES} element={<Exchanges />} />
              <Route path={ROUTES.SHIPPING} element={<ShippingClass />} />
              <Route path={ROUTES.MILES} element={<Miles />} />
              <Route path={ROUTES.DISTANCE} element={<Distances />} />
              <Route path={ROUTES.DELETION} element={<Deletions />} />
              <Route path={ROUTES.CHATLOGS} element={<ChatLogMessages />} />
              <Route path="*" element={<NotFound />} />
            </Route>
          </Routes>
        </SidebarNavigaton>
      )}
    </BrowserRouter>
  );
};

export default App;
