import { useDispatch, useSelector, useStore } from "react-redux";

import { userAction, userSelector } from "../../redux/reducers/user-reducer";
import { initialState } from "../../redux/reducers/user-reducer";
import {
  userExtensionAction,
  userExtensionSelector,
} from "../../redux/reducers/user-reducer-extension";

type UseUserProps = {
  userRegister: (payload: any) => any;
  userAccess: (payload: any) => any;
  userUpdate: (payload: any, token: any) => any;
  getUser: (payload: any) => any;
  userReset: (payload: any) => any;
  userUpdatePassword: (payload: any) => any;
  userBlackListing: (payload: any, token: any) => any;
  userAssignedRoles: (payload: any, token: any) => any;
  extension?: any;
} & typeof initialState;

export const useUser = (): UseUserProps => {
  const dispatch = useDispatch();
  const state = useSelector(userSelector);
  const extension = useSelector(userExtensionSelector);

  const userRegister = (payload: any) =>
    dispatch<any>(userAction({ path: "/user", payload }));
  const userAccess = (payload: any) =>
    dispatch<any>(userAction({ path: "/user/terminal", payload }));
  const userUpdate = (payload: any, token: any) =>
    dispatch<any>(userAction({ path: "/user/edit", payload, token }));
  const userBlackListing = (payload: any, token: any) =>
    dispatch<any>(userAction({ path: "/user/status", payload, token }));
  const userReset = (payload: any) =>
    dispatch<any>(userAction({ path: "/user/reset", payload }));
  const userUpdatePassword = (payload: any) =>
    dispatch<any>(userAction({ path: "/user/update", payload }));
  const userAssignedRoles = (payload: any, token: any) =>
    dispatch<any>(userAction({ path: "/user/assign-role", payload, token }));

  const getUser = (payload: any) =>
    dispatch<any>(userExtensionAction({ path: "/users", payload }));

  return {
    ...state,
    extension,
    userRegister,
    userAccess,
    userUpdate,
    getUser,
    userBlackListing,
    userReset,
    userUpdatePassword,
    userAssignedRoles,
  };
};
