import React from 'react';

import { baseURl } from '../../redux/env/host-url';

type FileInputProps = {
  FileTag:string
  FileTarget:"images"|"docx"|"download"
}

const useFileInput = ({ 
  FileTarget = "images", 
  FileTag, 
}:FileInputProps) => {
  const [files, setFiles] = React.useState<any[]>([]);

  const handleUploadFile = async () => {
    let path = FileTarget === "images" 
      ? "/upload-image" 
      : FileTarget === "docx"
      ? "/upload-docx" 
      : FileTarget === "download"
      ? "/download"+files[0]
      : "/upload/status";

  
    let formdata = new FormData();
    files[0] && formdata.append("file", files[0]);
    files[1] && formdata.append("file1", files[1]);
    files[2] && formdata.append("file2", files[2]);
    files[3] && formdata.append("file3", files[3]);
    formdata.append("tag", FileTag);
    
    let requestOptions:any = {
      method: FileTarget === 'download' ? 'GET' : 'POST',
      body: formdata,
      redirect: 'follow'
    };
    
    await fetch(`${baseURl+ path}`, requestOptions)
      .then(response => response.json())
      .then(result => alert(result?.message))
      .catch(error => console.log('error', error));

  };



  // console.log("files", files, FileTag);
  const handleDocumentSelection =async (filez:any) => {

    try {

      if (FileTarget === "images") {

        //internal use
        setFiles(filez);
        
      } 
      
      if (FileTarget === "docx") {
        
        //internal use
        setFiles(filez);
        
      }

    } catch (err) {
      console.warn(err);
    }
  };

  return {
    handleDocumentSelection,
    handleUploadFile,
    signal:files.length
  }
};

export default useFileInput;