import classNames from "classnames";
import React, { SyntheticEvent, useState } from "react";

import { formSchema } from "../../constants/form-names";
import { CreateOrUpdateForm, PermissionForm } from "../ui/create-or-update.tsx";
import SearchBar from "../ui/search-bar";
import TableLayout from "../ui/table";
import { TagShortDecription } from "../ui/tag-short-decription";
import { useModal } from "../ui/modal/modal.context";
import Loader from "../ui/spinner";
import { usePermission } from "../hook/permission";
import { useSearchEngineFull } from "../hook/search-engine";
import { ErrorPage } from "./error";
import { useProduct } from "../hook/use-product";
import { usePersonal } from "../hook/user-detail";
import { submitter } from "../hook/submitter";
import { SubmitterPermission } from "../hook/submitter-permission";
import ControlButton from "../ui/button/control-button";
import ActionButton from "../ui/button/action-button";
import {
  ExtensionFormValuesProps,
  PermissionFormValuesProps,
} from "../../types";
import { baseURl } from "../../redux/env/host-url";
import useFileInput from "../hook/file-handler";
import { useShop } from "../hook/use-shop";

const pageSettings = {
  pageName: "products",
  item: {
    counter: true,
  },
  schema: {
    form: "productForm",
    option: "productOptionForm",
    formKeys: formSchema
      .filter((v) => v.schema === "productForm")
      .map((v) => v.key),
    searchColumns: ["id"].concat(
      formSchema
        .filter((v) => v.schema === "productForm")
        .map((v) => v.key)
        .slice(1, 2) as any[]
    ),
    searchIndex: 7,
    formExtension: [
      { name: "category", title: "Category" },
      { name: "subcategory", title: "Subcategory" },
      { name: "currency", title: "Currency" },
      { name: "shops", title: "Shop" },
    ],
  },
  explore: {
    isOpen: false,
    editor: 0,
    item: null,
    currentItemOriginalDetails: null,
  },
  control: {
    allowedControl: true,
    restrictions: [],
  },
};

function MyProducts() {
  const { data } = usePersonal(true);
  const isAdmin = usePermission(2);
  const { openModal } = useModal();
  const [explored, explore] = useState<typeof pageSettings.explore | any>(
    pageSettings.explore
  );
  const [notifier, setNotifier] = useState<boolean>(false);
  const [filename, setfilename] = useState<string>("");
  const searcher = useSearchEngineFull();
  const products = useProduct();
  const shops = useShop();
  const [permissionFormValues, setpermissionFormValues] =
    React.useState<PermissionFormValuesProps>({
      is_active: false,
      ability: "",
      type: "",
      premier: "",
      product_type: "",
      state: "",
      id: null,
    });
  const [extensionFormValues, setextensionFormValues] =
    React.useState<ExtensionFormValuesProps>({
      address: null,
      category: null,
      subcategory: null,
      brand: null,
      subpscriptionPlanType: null,
      subpscriptionPlanPeriod: null,
      shop: null,
      currency: null,
    });

  //preset calls
  let owner: any = data;
  const information = products,
    listInformation = searcher.info?.HLL ?? products.info?.HLL?.data;

  const name = (filename + owner?.id).replace(/(\s)/gi, "_");
  const { handleDocumentSelection, handleUploadFile } = useFileInput({
    FileTag: name,
    FileTarget: "images",
  });

  React.useEffect(() => {
    products.getProduct({ page: 1 });
  }, []);

  React.useEffect(() => {
    shops.getShopSimple({ owner_id: owner?.id });
  }, [owner]);

  //___list
  //select on list
  const handleSelect = (item) => {
    const currentItemOriginalDetails = information.info?.HLL?.data.find(
      (v) => v.id === item.ID
    );
    setfilename(currentItemOriginalDetails.name); //capture name for image
    return explore({
      isOpen: true,
      editor: 1,
      item,
      currentItemOriginalDetails,
    });
  };
  //regenerate list with columns' title
  const listed =
      typeof information.info === "object" &&
      information.info?.HLL?.data.length !== undefined
        ? listInformation
            .filter((v) => v?.shop?.owner_id === owner?.id)
            .map((item) => {
              // console.log("original", item);
              const selectedDetail = filterInformation(item);
              let currentProperties = Object.entries(selectedDetail),
                columns = currentProperties.map((property) => {
                  return (
                    property[0] !== "category_id" &&
                    property[0] !== "subcategory_id" && {
                      title:
                        property[0] === "state"
                          ? "Status"
                          : property[0].charAt(0).toUpperCase() +
                            property[0]?.replace(/[(_)(-)]/gi, " ").slice(1),
                      dataIndex: property[0],
                      key: property[0],
                      render: (value, row) => (
                        <div
                          className={classNames(
                            "text-justify mx-5 cursor-pointer text-[15px]",
                            {
                              "bg-green-800 rounded-full h-3 w-3":
                                property[0] === "state" && value === "active",
                            },
                            {
                              "bg-red-800 rounded-full h-3 w-3":
                                property[0] === "state" && value === "draft",
                            }
                          )}
                          onClick={() => handleSelect(row)}
                          onDoubleClick={() =>
                            openModal({ view: "ALERT", payload: value })
                          }
                        >
                          {property[0] !== "state" &&
                            property[0] === "image" && (
                              <span className="underline text-blue-600">
                                double click
                              </span>
                            )}
                          {property[0] !== "state" &&
                            property[0] !== "image" &&
                            value}
                        </div>
                      ),
                    }
                  );
                });
              return {
                ...selectedDetail,
                columns,
              };
            })
        : [],
    onwerShops = shops.info?.HLL ?? [];

  //searching
  const handleSearch = (searchTerm) => {
    searcher.findRelated({
      keyIndex: pageSettings.schema.searchIndex,
      value: searchTerm,
      targets: pageSettings.schema.searchColumns,
    });
  };

  //create or update
  const handleSubmit = (e) => {
    e.preventDefault();

    const images = [
      {
        id: Math.random(),
        image: `${baseURl}/files/images/${name}_1.png`,
      },
      {
        id: Math.random(),
        image: `${baseURl}/files/images/${name}_2.png`,
      },
      {
        id: Math.random(),
        image: `${baseURl}/files/images/${name}_3.png`,
      },
      {
        id: Math.random(),
        image: `${baseURl}/files/images/${name}_4.png`,
      },
    ];

    let payload = submitter(e, extensionFormValues, explored, owner, images);

    setNotifier(!notifier);
    information.createOrUpdateProduct(payload);
  };

  // acitvation and deactivation
  const handleActivation = () => {
    let inputValue = SubmitterPermission(pageSettings, permissionFormValues),
      payload = {
        ...inputValue,
        id: explored?.currentItemOriginalDetails?.id,
      };
    setNotifier(!notifier);
    information.deactivateProduct({ ...payload, type: "state" });
  };

  // deleting
  const handleDelete = () => {
    let payload = {
      tyype: "clear",
      id: explored?.currentItemOriginalDetails?.id,
    };
    setNotifier(!notifier);
    information.deleteProduct({ ...payload, type: "clear" });
  };

  React.useEffect(() => {
    if (notifier && information.error) {
      openModal({ view: "ALERT", payload: information.error });
      setTimeout(() => {
        setNotifier(!notifier);
      }, 1500);
    } else if (notifier && information.info?.query) {
      openModal({ view: "ALERT", payload: information.info?.query });
      setTimeout(() => {
        setNotifier(!notifier);
      }, 1500);
    }
  }, [information]);

  if (information.loading) {
    return <Loader />;
  }

  if (information.error) {
    return <ErrorPage />;
  }

  return (
    <div className="mt-5 md:mt-0 w-5/6">
      {/* tag */}
      <TagShortDecription
        title="Administrative"
        description="This is section is visible to authorized person(s)"
        outerlayerStyle="md:col-span-1"
        innerBodyStyle="px-4 sm:px-0 my-10"
        innerHeaderStyle="text-lg font-medium leading-6 text-gray-900"
        innerlayerStyle="mt-1 text-sm text-gray-600"
      />

      {/* ssearch */}
      <div className="bg-white my-5 p-3 lg:p-5 md:p-10 focus:ring-indigo-500 shadow">
        <SearchBar searching={(v) => handleSearch(v)} />
        <ControlButton
          onClick={() => explore({ isOpen: !explored.isOpen, editor: 1 })}
          toggleCondition={explored.isOpen}
          authenticity={pageSettings.item.counter && isAdmin}
          totalCount={information.info?.HLL?.total}
        />
      </div>

      {/* edit section */}
      {explored.isOpen && (
        <div className=" border border-white shadow">
          <ActionButton
            onClickEditor={() => explore({ isOpen: true, editor: 1 })}
            onClickControl={() =>
              explore({ ...explored, isOpen: true, editor: 2 })
            }
            styliCondition={explored.editor}
          />

          <div className="lg:grid lg:grid-flow-col lg:gap-3">
            {explored.editor === 1 && (
              <CreateOrUpdateForm
                submitImage={() => {
                  if (!filename) {
                    return openModal({
                      view: "ALERT",
                      payload: "Product name is not available yet",
                    });
                  }
                  handleUploadFile();
                }}
                selectImage={(image) => handleDocumentSelection(image)}
                schema={formSchema}
                schemaSelect={pageSettings.schema.form}
                item={explored.item}
                submit={handleSubmit}
                clear={() => {
                  setextensionFormValues({
                    address: null,
                    category: null,
                    subcategory: null,
                    brand: null,
                    subpscriptionPlanType: null,
                    subpscriptionPlanPeriod: null,
                    shop: null,
                    currency: null,
                  });
                  explore({
                    ...explored,
                    item: null,
                    currentItemOriginalDetails: null,
                  });
                }}
                onChange={(e: any) => e.index === 1 && setfilename(e.value)}
                autoTitle={true}
                countrySelected={(v: any) => {
                  setextensionFormValues({
                    ...extensionFormValues,
                    address: {
                      ...extensionFormValues.address,
                      country: v.country_name,
                      country_details: {
                        ...extensionFormValues.address?.country_details,
                        ...v,
                      },
                    },
                  });
                }}
                stateSelected={(v: any) => {
                  setextensionFormValues({
                    ...extensionFormValues,
                    address: {
                      ...extensionFormValues.address,
                      state: v.state_name,
                      state_details: {
                        ...extensionFormValues.address?.state_details,
                        ...v,
                      },
                    },
                  });
                }}
                citySelected={(v: any) => {
                  setextensionFormValues({
                    ...extensionFormValues,
                    address: {
                      ...extensionFormValues.address,
                      city: v.city_name,
                      city_details: {
                        ...extensionFormValues.address?.city_details,
                        ...v,
                      },
                    },
                  });
                }}
                defaultCountry={explored?.item?.country}
                defaultState={explored?.item?.state}
                defaultCity={explored?.item?.city}
                category={{
                  onPick: (v) =>
                    setextensionFormValues({
                      ...extensionFormValues,
                      category: {
                        ...extensionFormValues.category,
                        name: v.name,
                        id: v.id,
                        category_id: v.id,
                      },
                    }),
                  title: pageSettings.schema.formExtension[0].title,
                  schema: pageSettings.schema.formExtension[0].name,
                  displayValuePropertyIndex: 1,
                  listValueType: "objects",
                  required: true,
                }}
                subcategory={{
                  onPick: (v) =>
                    setextensionFormValues({
                      ...extensionFormValues,
                      subcategory: {
                        ...extensionFormValues.subcategory,
                        subcategory_name: v.name,
                        serial: v.serial,
                        subcategory_id: v.id,
                      },
                    }),
                  title: pageSettings.schema.formExtension[1].title,
                  schema: pageSettings.schema.formExtension[1].name,
                  list: [
                    { name: "book", author: "meme" },
                    { name: "clothes", author: "testme" },
                  ],
                  displayValuePropertyIndex: 1,
                  listValueType: "objects",
                  required: true,
                }}
                currency={{
                  onPick: (v) =>
                    setextensionFormValues({
                      ...extensionFormValues,
                      currency: {
                        ...extensionFormValues.currency,
                        ...v,
                      },
                    }),
                  title:
                    pageSettings.schema.formExtension[2].title +
                    " ( Unique Currency per Shop )",
                  schema: pageSettings.schema.formExtension[2].name,
                  listValueType: "objects",
                  displayValuePropertyIndex: 3,
                  required: true,
                }}
                shops={{
                  onPick: (v) =>
                    setextensionFormValues({
                      ...extensionFormValues,
                      shop: {
                        ...extensionFormValues.shop,
                        shop_id: v?.id,
                      },
                    }),
                  title: pageSettings.schema.formExtension[3].title,
                  schema: pageSettings.schema.formExtension[3].name,
                  list: onwerShops,
                  listValueType: "objects",
                  displayValuePropertyIndex: 1,
                  required: true,
                }}
              />
            )}
            {explored.editor === 2 && (
              <PermissionForm
                schema={formSchema}
                item={explored.item}
                schemaSelect={pageSettings.schema.option}
                submit={(e: SyntheticEvent) => {
                  e.preventDefault();
                  handleActivation();
                }}
                onChange={(v) => {
                  if (v?.title === "yes" || v?.title === "no") {
                    return setpermissionFormValues({
                      ...permissionFormValues,
                      premier: v?.title,
                      product_type:
                        explored?.currentItemOriginalDetails?.product_type,
                      state: explored?.currentItemOriginalDetails?.state,
                    });
                  }

                  if (v?.title === "new" || v?.title === "pre-owned") {
                    return setpermissionFormValues({
                      ...permissionFormValues,
                      premier: explored?.currentItemOriginalDetails?.premier,
                      product_type: v?.title === "new" ? "new" : "preown",
                      state: explored?.currentItemOriginalDetails?.state,
                    });
                  }

                  if (v?.title === "active" || v?.title === "draft") {
                    return setpermissionFormValues({
                      ...permissionFormValues,
                      premier: explored?.currentItemOriginalDetails?.premier,
                      product_type:
                        explored?.currentItemOriginalDetails?.product_type,
                      state: v?.title,
                    });
                  }
                }}
                clearButtonTitle="Delete"
                onClear={(e: SyntheticEvent) => {
                  e.preventDefault();
                  handleDelete();
                }}
                setClearButtonTitle={true}
                clearButtonStyli="bg-red-300 hover:bg-red-400"
                defaultChecked={[
                  explored?.currentItemOriginalDetails?.premier,
                  explored?.currentItemOriginalDetails?.product_type?.replace(
                    "eo",
                    "e-o"
                  ),
                  explored?.currentItemOriginalDetails?.state,
                ]}
              />
            )}
          </div>
        </div>
      )}

      {/* list */}
      <TableLayout
        list={listed}
        pagination={{
          from: information.info?.HLL?.from,
          to: information.info?.HLL?.to,
          total: information.info?.HLL?.total,
          current: information.info?.HLL?.current_page,
          last: information.info?.HLL?.last_page,
          goto: (pageNumber: number) =>
            information.getProduct({ page: pageNumber }),
        }}
      />
    </div>
  );
}
export default MyProducts;

function filterInformation(info: any) {
  let selectedDetails = {
    state: info?.state,
    ID: info?.id,
    image: JSON.stringify(info?.image),
    name: info?.name,
    description: info?.description,
    category: info?.category?.name,
    subcategory: info?.subcategory?.name,
    currency: info?.currency?.code,
    price: info?.price,
    sale_price: info?.sale_price,
    in_stock:
      String(info?.in_stock).includes("-") || info?.in_stock === 0
        ? "Out of Stock"
        : info?.in_stock,
    unit: info?.unit,
    height: info?.height,
    width: info?.width,
    length: info?.length,
    colour: info?.variety_option?.color,
    size: info?.variety_option?.size,
    brand: info?.brand?.name,
    category_id: info?.category?.id,
    subcategory_id: info?.subcategory?.id,
  };
  return selectedDetails;
}
