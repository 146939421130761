import classNames from "classnames";
import React, { SyntheticEvent, useState } from "react";

import { formSchema } from "../../constants/form-names";
import { PermissionForm } from "../ui/create-or-update.tsx";
import SearchBar from "../ui/search-bar";
import TableLayout from "../ui/table";
import { TagShortDecription } from "../ui/tag-short-decription";
import { useModal } from "../ui/modal/modal.context";
import Loader from "../ui/spinner";
import { usePermission } from "../hook/permission";
import { useSearchEngineFull } from "../hook/search-engine";
import { ErrorPage } from "./error";
import { useUser } from "../hook/use-user";
import { usePersonal } from "../hook/user-detail";
import { SubmitterPermission } from "../hook/submitter-permission";
import ControlButton from "../ui/button/control-button";
import ActionButton from "../ui/button/action-button";
import { PermissionFormValuesProps } from "../../types";
import { useShop } from "../hook/use-shop";
import useToken from "../hook/token";
import { useApply } from "../hook/apply-for-jobs";

const pageSettings = {
  pageName: "applicants",
  item: {
    counter: true,
  },
  schema: {
    form: "",
    option: "",
    formKeys: [],
    searchColumns: ["id", "title", "country"],
    searchIndex: 19,
    formExtension: [],
  },
  explore: {
    isOpen: false,
    editor: 0,
    item: null,
    currentItemOriginalDetails: null,
  },
  control: {
    allowedControl: true,
    restrictions: [],
  },
};

function Applicants() {
  const { data } = usePersonal(true);
  const [token] = useToken();
  const isAdmin = usePermission(2);
  const { openModal } = useModal();
  const [explored, explore] = useState<typeof pageSettings.explore | any>(
    pageSettings.explore
  );
  const [notifier, setNotifier] = useState<boolean>(false);
  const searcher = useSearchEngineFull();
  const users = useUser();
  const shops = useShop();
  const applicants = useApply();
  const [permissionFormValues, setpermissionFormValues] =
    React.useState<PermissionFormValuesProps>({
      is_active: false,
      ability: "",
      type: "",
      premier: "",
      product_type: "",
      state: "",
      id: null,
    });

  //preset calls
  let owner: any = data;
  const information = applicants,
    listInformation = searcher.info?.HLL ?? applicants?.info?.HLL?.data;

  React.useEffect(() => {
    users.getUser({ id: owner?.id, page: 1, permission: 3 });
    shops.getShopSimple({ owner_id: owner?.id });
    applicants.getApplicant({ page: 1 });
  }, [owner]);

  React.useEffect(() => {
    if (notifier && information.error) {
      openModal({ view: "ALERT", payload: information.error });
      setTimeout(() => {
        setNotifier(!notifier);
      }, 1500);
    } else if (notifier && information.info?.query) {
      openModal({ view: "ALERT", payload: information.info?.query });
      setTimeout(() => {
        setNotifier(!notifier);
      }, 1500);
    }
  }, [information]);

  //___list
  //select on list
  const handleSelect = (item) => {
    const currentItemOriginalDetails = information.info?.HLL?.data.find(
      (v) => v.id === item.ID
    );
    return explore({
      isOpen: true,
      editor: 2,
      item,
      currentItemOriginalDetails,
    });
  };
  //regenerate list with columns' title
  const listed =
      typeof information.info === "object" &&
      information.info?.HLL?.data.length !== undefined
        ? listInformation.map((item) => {
            // console.log("original", item);
            const selectedDetail = filterInformation(item);
            let currentProperties = Object.entries(selectedDetail),
              columns = currentProperties.map((property) => {
                return {
                  title:
                    property[0] === "is_active"
                      ? "Status"
                      : property[0].charAt(0).toUpperCase() +
                        property[0]?.replace(/[(_)(-)]/gi, " ").slice(1),
                  dataIndex: property[0],
                  key: property[0],
                  render: (value, row) => (
                    <div
                      className={classNames(
                        "text-justify mx-5 cursor-pointer text-[15px]",
                        {
                          "bg-green-800 rounded-full h-3 w-3":
                            property[0] === "is_active" &&
                            (value || value === 1),
                        },
                        {
                          "bg-red-800 rounded-full h-3 w-3":
                            property[0] === "is_active" &&
                            (!value || value === 0),
                        }
                      )}
                      onClick={() => handleSelect(row)}
                      onDoubleClick={() =>
                        openModal({ view: "ALERT", payload: value })
                      }
                    >
                      {property[0] === "cV" && (
                        <a className="underline text-blue-600" href={value}>
                          click
                        </a>
                      )}
                      {property[0] !== "cV" && property[0] !== "image" && value}
                    </div>
                  ),
                };
              });
            return {
              ...selectedDetail,
              columns,
            };
          })
        : [],
    onwerShops = shops.info?.HLL ?? [];

  //searching
  const handleSearch = (searchTerm) => {
    searcher.findRelated({
      keyIndex: pageSettings.schema.searchIndex,
      value: searchTerm,
      targets: pageSettings.schema.searchColumns,
    });
  };

  if (information.loading) {
    return <Loader />;
  }

  if (information.error) {
    return <ErrorPage />;
  }

  return (
    <div className="mt-5 md:mt-0 w-5/6">
      {/* tag */}
      <TagShortDecription
        title="Administrative"
        description="This is section is only visible to administrators"
        outerlayerStyle="md:col-span-1"
        innerBodyStyle="px-4 sm:px-0 my-10"
        innerHeaderStyle="text-lg font-medium leading-6 text-gray-900"
        innerlayerStyle="mt-1 text-sm text-gray-600"
      />

      {/* ssearch */}
      <div className="bg-white my-5 p-3 lg:p-5 md:p-10 focus:ring-indigo-500 shadow">
        <SearchBar searching={(v) => handleSearch(v)} />
        <ControlButton
          onClick={() => explore({ isOpen: false, editor: 0 })}
          toggleCondition={false}
          authenticity={pageSettings.item.counter && isAdmin}
          totalCount={information.info?.HLL?.total}
        />
      </div>

      {/* list */}
      <TableLayout
        list={listed}
        pagination={{
          from: information.info?.HLL?.from,
          to: information.info?.HLL?.to,
          total: information.info?.HLL?.total,
          current: information.info?.HLL?.current_page,
          last: information.info?.HLL?.last_page,
          goto: (pageNumber: number) =>
            information.getApplicant({ page: pageNumber }),
        }}
      />
    </div>
  );
}
export default Applicants;

function filterInformation(info: any) {
  let selectedDetails = {
    ID: info?.id,
    title: info?.title,
    cV: info?.cv,
    country: info?.country,
    name: info?.user?.name,
    email: info?.user?.email,
    phone: info?.user?.phone,
  };
  return selectedDetails;
}
