import classNames from "classnames";
import React, { SyntheticEvent, useState } from "react";

import { formSchema } from "../../constants/form-names";
import { CreateOrUpdateForm, PermissionForm } from "../ui/create-or-update.tsx";
import SearchBar from "../ui/search-bar";
import TableLayout from "../ui/table";
import { TagShortDecription } from "../ui/tag-short-decription";
import { useModal } from "../ui/modal/modal.context";
import Loader from "../ui/spinner";
import { usePermission } from "../hook/permission";
import { useSearchEngineFull } from "../hook/search-engine";
import { ErrorPage } from "./error";
import { useDistances } from "../hook/use-distances";
import { usePersonal } from "../hook/user-detail";
import { submitter } from "../hook/submitter";
import ControlButton from "../ui/button/control-button";
import ActionButton from "../ui/button/action-button";
import { ExtensionFormValuesProps } from "../../types";
import { SelectCountry } from "../ui/selection-box";
import { InputBox } from "../ui/input-box";

let stylii = {
  classNameInput:
    "mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md",
  classNameLabel: "block text-sm font-medium text-gray-700 capitalize",
  classNameGrouped: "col-span-6",
  classForm: "w-full max-h-screen overflow-y-auto",
  classFormGrid: "",
  ImageLayout: "w-[230px] sm:w-[300px]",
  topGrid: "col-span-9 lg:w-full",
};

const pageSettings = {
  pageName: "distances",
  item: {
    counter: true,
  },
  schema: {
    form: "customDistancesForm",
    option: "distancesOptionForm",
    formKeys: [
      {
        key: "location_A",
        value: "Location A",
        placeholderTexT: "Country,State,Area",
        attach: 0,
        required: true,
        schema: "customDistancesForm",
        display: true,
      },
      {
        key: "location_B",
        value: "Location B",
        placeholderTexT: "Country,State,Area",
        attach: 0,
        required: true,
        schema: "customDistancesForm",
        display: true,
      },
      {
        key: "distance_km",
        value: "Distance (Kilometer)",
        placeholderTexT: "536",
        attach: 0,
        required: true,
        schema: "customDistancesForm",
        display: true,
      },
    ],
    searchColumns: ["id", "location_A", "location_B", "distance_km"],
    searchIndex: 21,
    formExtension: [],
  },
  explore: {
    isOpen: false,
    editor: 0,
    item: null,
    currentItemOriginalDetails: null,
  },
  control: {
    allowedControl: true,
    restrictions: [],
  },
};

interface DistanceProps {
  location_A: string;
  location_B: string;
  distance_km: string;
}

interface LocationProps {
  country?: string | null;
  state?: string | null;
  area?: string | null;
}

function Distances() {
  const { data } = usePersonal(true);
  const isAdmin = usePermission(2);
  const { openModal } = useModal();
  const [explored, explore] = useState<typeof pageSettings.explore | any>(
    pageSettings.explore
  );
  const [notifier, setNotifier] = useState<boolean>(false);
  const searcher = useSearchEngineFull();
  const distances = useDistances();
  const [extensionFormValues, setextensionFormValues] = React.useState<
    ExtensionFormValuesProps | any
  >({
    address: null,
    category: null,
    subcategory: null,
    brand: null,
    subpscriptionPlanType: null,
    subpscriptionPlanPeriod: null,
    shop: null,
    currency: null,
  });

  const [selectOption, setselectOption] = React.useState<number>(1),
    [location, setlocation] = React.useState<LocationProps>({
      country: "",
      state: "",
      area: "",
    }),
    [distance, setdistance] = React.useState<DistanceProps>({
      location_A: "",
      location_B: "",
      distance_km: "",
    });

  //preset calls
  let owner: any = data;
  const information = distances,
    listInformation = searcher.info?.HLL ?? distances.info?.HLL;

  React.useEffect(() => {
    distances.getDistances({ page: 1 });
  }, []);

  //___list
  //select on list
  const handleSelect = (item) => {
    const currentItemOriginalDetails = information.info?.HLL?.find(
      (v) => v.id === item.ID
    );
    return explore({
      isOpen: true,
      editor: 1,
      item,
      currentItemOriginalDetails,
    });
  };
  //regenerate list with columns' title
  const listed =
    typeof information.info === "object" &&
    information.info?.HLL?.length !== undefined
      ? listInformation.map((item) => {
          // console.log("original", item);
          const selectedDetail = filterInformation(item);
          let currentProperties = Object.entries(selectedDetail),
            columns = currentProperties.map((property) => {
              return {
                title:
                  property[0] === "is_active"
                    ? "Status"
                    : property[0].charAt(0).toUpperCase() +
                      property[0]?.replace(/[(_)(-)]/gi, " ").slice(1),
                dataIndex: property[0],
                key: property[0],
                render: (value, row) => (
                  <div
                    className={classNames(
                      "text-justify mx-5 cursor-pointer text-[15px]",
                      {
                        "bg-green-800 rounded-full h-3 w-3":
                          property[0] === "is_active" && (value || value === 1),
                      },
                      {
                        "bg-red-800 rounded-full h-3 w-3":
                          property[0] === "is_active" &&
                          (!value || value === 0),
                      }
                    )}
                    onClick={() => handleSelect(row)}
                    onDoubleClick={() =>
                      openModal({ view: "ALERT", payload: value })
                    }
                  >
                    {property[0] !== "is_active" && value}
                  </div>
                ),
              };
            });
          return {
            ...selectedDetail,
            columns,
          };
        })
      : [];

  //searching
  const handleSearch = (searchTerm) => {
    searcher.findRelated({
      keyIndex: pageSettings.schema.searchIndex,
      value: searchTerm,
      targets: pageSettings.schema.searchColumns,
    });
  };

  //create or update
  const handleSubmit = (e) => {
    e.preventDefault();

    //  console.log("distance", { ...distance, id: distance?.ID });
    setNotifier(!notifier);
    information.createOrUpdateDistances({
      ...distance,
      // @ts-ignore
      id: explored?.item?.ID,
    });

    return setdistance({
      location_A: "",
      location_B: "",
      distance_km: "",
    });
  };

  // deleting
  const handleDelete = () => {
    //  console.log("distance", { id: explored.item?.ID });
    setNotifier(!notifier);
    information.deleteDistances({
      // @ts-ignore
      id: explored?.item?.ID,
    });

    return setdistance({
      location_A: "",
      location_B: "",
      distance_km: "",
    });
  };

  React.useEffect(() => {
    if (notifier && information.error) {
      openModal({ view: "ALERT", payload: information.error });
      setTimeout(() => {
        setNotifier(!notifier);
      }, 1500);
    } else if (notifier && information.info?.query) {
      openModal({ view: "ALERT", payload: information.info?.query });
      setTimeout(() => {
        setNotifier(!notifier);
      }, 1500);
    }
  }, [information]);

  const handleOption = async ({ country, state, area }: LocationProps) => {
    await setlocation({
      country: country ? country : location.country,
      state: state ? state : location.state,
      area: area ? area : location.area,
    });
    setdistance(() => {
      if (selectOption === 2) {
        return {
          ...distance,
          location_B:
            (country ?? location.country) +
            ("," + (state ?? location.state)) +
            ("," + (area ?? location.area)),
        };
      }
      return {
        ...distance,
        location_A:
          (country ?? location.country) +
          ("," + (state ?? location.state)) +
          ("," + (area ?? location.area)),
      };
    });
  };

  if (information.loading) {
    return <Loader />;
  }

  if (information.error) {
    return <ErrorPage />;
  }

  return (
    <div className="mt-5 md:mt-0 w-5/6">
      {/* tag */}
      <TagShortDecription
        title="Administrative"
        description="This is section is only visible to administrators"
        outerlayerStyle="md:col-span-1"
        innerBodyStyle="px-4 sm:px-0 my-10"
        innerHeaderStyle="text-lg font-medium leading-6 text-gray-900"
        innerlayerStyle="mt-1 text-sm text-gray-600"
      />

      {/* ssearch */}
      <div className="bg-white my-5 p-3 lg:p-5 md:p-10 focus:ring-indigo-500 shadow">
        <SearchBar searching={(v) => handleSearch(v)} />
        <ControlButton
          onClick={() => explore({ isOpen: !explored.isOpen, editor: 1 })}
          toggleCondition={explored.isOpen}
          authenticity={pageSettings.item.counter && isAdmin}
          totalCount={listInformation?.length}
        />
      </div>

      {/* edit section */}
      {explored.isOpen && (
        <div className="border border-white shadow">
          <ActionButton
            onClickEditor={() => explore({ isOpen: true, editor: 1 })}
            onClickControl={() => ""}
            styliCondition={explored.editor}
          />

          <div className="lg:grid lg:grid-flow-col lg:gap-3">
            {explored.editor === 1 && (
              <>
                <div className="col-span-2 mt-[28px]">
                  {pageSettings.schema.formKeys.map((value, index) => (
                    <InputBox
                      key={index}
                      type="text"
                      name={value.key}
                      classNameInput={classNames(stylii.classNameInput)}
                      classNameLabel={classNames(stylii.classNameLabel)}
                      classNameGrouped={classNames(stylii.classNameGrouped)}
                      placeholder={
                        explored.item
                          ? explored.item[value.key]
                          : value.placeholderTexT
                      }
                      requiredByIndicator={value.required}
                      labelTitle={value.value}
                      defaultValue={value ? distance[value.key] : ""}
                      onChange={(e) => {
                        setdistance({
                          ...distance,
                          distance_km: explored?.item?.distance_km,
                          [value.key]: e.target.value,
                        });
                      }}
                    />
                  ))}
                  <div>
                    <button
                      type="button"
                      onClick={handleSubmit}
                      className="my-3 mx-1 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-500 hover:bg-indigo-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                      Save
                    </button>
                    <button
                      type="button"
                      onClick={handleDelete}
                      className="my-3 mx-1 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-500 hover:bg-red-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                      Delete
                    </button>
                  </div>
                </div>
                <div className="">
                  <div className="flex justify-evenly items-center">
                    <div className="flex justify-center items-center">
                      <input
                        type="radio"
                        name="location"
                        className="m-2"
                        checked={selectOption === 1 && true}
                        onClick={() => setselectOption(1)}
                      />
                      <span>Location A</span>
                    </div>
                    <div className="flex justify-center items-center">
                      <input
                        type="radio"
                        name="location"
                        className="m-2"
                        onClick={() => setselectOption(2)}
                      />
                      <span>Location B</span>
                    </div>
                  </div>
                  <SelectCountry
                    autoTitle={true}
                    countrySelected={(v: any) => {
                      setextensionFormValues({
                        ...extensionFormValues,
                        address: {
                          ...extensionFormValues.address,
                          country: v.country_name,
                          country_details: {
                            ...extensionFormValues.address?.country_details,
                            ...v,
                          },
                        },
                      });
                      return handleOption({ country: v.country_name });
                    }}
                    stateSelected={(v: any) => {
                      setextensionFormValues({
                        ...extensionFormValues,
                        address: {
                          ...extensionFormValues.address,
                          state: v.state_name,
                          state_details: {
                            ...extensionFormValues.address?.state_details,
                            ...v,
                          },
                        },
                      });
                      return handleOption({ state: v.state_name });
                    }}
                    citySelected={(v: any) => {
                      setextensionFormValues({
                        ...extensionFormValues,
                        address: {
                          ...extensionFormValues.address,
                          city: v.city_name,
                          city_details: {
                            ...extensionFormValues.address?.city_details,
                            ...v,
                          },
                        },
                      });
                      return handleOption({ area: v.city_name });
                    }}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      )}

      {/* list */}
      <TableLayout
        list={listed}
        pagination={{
          from: information.info?.HLL?.from,
          to: information.info?.HLL?.to,
          total: information.info?.HLL?.total,
          current: information.info?.HLL?.current_page,
          last: information.info?.HLL?.last_page,
          goto: (pageNumber: number) =>
            information.getDistances({ page: pageNumber }),
        }}
      />
    </div>
  );
}
export default Distances;

function filterInformation(info: any) {
  let selectedDetails = {
    ID: info?.id,
    location_A: info?.location_A,
    location_B: info?.location_B,
    distance_km: info?.distance_km,
  };
  return selectedDetails;
}
