import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';

import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import store from './redux/config';
import { ModalProvider } from './components/ui/modal/modal.context';
import { ModalManager } from './components/ui/modal/modal-manager';
import { RemoteControlProvider } from './components/ui/modal/toggler';

const rootElement:any = document.getElementById('root');
const root = ReactDOM.createRoot(rootElement);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <RemoteControlProvider>
        <ModalProvider>
            <App />
            <ModalManager/>
        </ModalProvider>
      </RemoteControlProvider>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
