import classNames from "classnames";
import React, { SyntheticEvent, useState } from "react";

import { formSchema } from "../../constants/form-names";
import { PermissionForm } from "../ui/create-or-update.tsx";
import SearchBar from "../ui/search-bar";
import TableLayout from "../ui/table";
import { TagShortDecription } from "../ui/tag-short-decription";
import { useModal } from "../ui/modal/modal.context";
import Loader from "../ui/spinner";
import { usePermission } from "../hook/permission";
import { useSearchEngineFull } from "../hook/search-engine";
import { ErrorPage } from "./error";
import { useUser } from "../hook/use-user";
import { usePersonal } from "../hook/user-detail";
import { SubmitterPermission } from "../hook/submitter-permission";
import ControlButton from "../ui/button/control-button";
import ActionButton from "../ui/button/action-button";
import { PermissionFormValuesProps } from "../../types";
import { useShop } from "../hook/use-shop";
import useToken from "../hook/token";
import { sidebarLinks } from "../ui/sidebar/sidebar.context";
import { accessControlAdmin } from "../../constants/access-control";

const pageSettings = {
  pageName: "users",
  item: {
    counter: true,
  },
  schema: {
    form: "userForm",
    option: "userOptionForm",
    formKeys: formSchema
      .filter((v) => v.schema === "userForm")
      .map((v) => v.key),
    searchColumns: ["id"].concat(
      formSchema
        .filter((v) => v.schema === "userForm")
        .map((v) => v.key) as any[]
    ),
    searchIndex: 0,
    formExtension: [],
  },
  explore: {
    isOpen: false,
    editor: 0,
    item: null,
    currentItemOriginalDetails: null,
  },
  control: {
    allowedControl: true,
    restrictions: [],
  },
};

function Users() {
  const { data } = usePersonal(true);
  const [token] = useToken();
  const isAdmin = usePermission(2);
  const { openModal } = useModal();
  const [explored, explore] = useState<typeof pageSettings.explore | any>(
    pageSettings.explore
  );
  const [notifier, setNotifier] = useState<boolean>(false);
  const searcher = useSearchEngineFull();
  const users = useUser();
  const shops = useShop();
  const [permissionFormValues, setpermissionFormValues] =
    React.useState<PermissionFormValuesProps>({
      is_active: false,
      ability: "",
      type: "",
      premier: "",
      product_type: "",
      state: "",
      id: null,
    });

  //preset calls
  let owner: any = data;
  const information = users,
    listInformation = searcher.info?.HLL ?? users.extension?.info?.HLL?.data;

  React.useEffect(() => {
    users.getUser({ id: owner?.id, page: 1, permission: 3 });
    shops.getShopSimple({ owner_id: owner?.id });
  }, [owner]);

  React.useEffect(() => {
    if (notifier && information.error) {
      openModal({ view: "ALERT", payload: information.error });
      setTimeout(() => {
        setNotifier(!notifier);
      }, 1500);
    } else if (notifier && information.info?.query) {
      openModal({ view: "ALERT", payload: information.info?.query });
      setTimeout(() => {
        setNotifier(!notifier);
      }, 1500);
    }
  }, [information]);

  //___list
  //select on list
  const handleSelect = (item) => {
    const currentItemOriginalDetails =
      information.extension?.info?.HLL?.data.find((v) => v.id === item.ID);
    return explore({
      isOpen: true,
      editor: 2,
      item,
      currentItemOriginalDetails,
    });
  };
  //regenerate list with columns' title
  const listed =
      typeof information.info === "object" &&
      information.extension?.info?.HLL?.data.length !== undefined
        ? listInformation.map((item) => {
            // console.log("original", item);
            const selectedDetail = filterInformation(item);
            let currentProperties = Object.entries(selectedDetail),
              columns = currentProperties.map((property) => {
                return {
                  title:
                    property[0] === "is_active"
                      ? "Status"
                      : property[0].charAt(0).toUpperCase() +
                        property[0]?.replace(/[(_)(-)]/gi, " ").slice(1),
                  dataIndex: property[0],
                  key: property[0],
                  render: (value, row) => (
                    <div
                      className={classNames(
                        "text-justify mx-5 cursor-pointer text-[15px]",
                        {
                          "bg-green-800 rounded-full h-3 w-3":
                            property[0] === "is_active" &&
                            (value || value === 1),
                        },
                        {
                          "bg-red-800 rounded-full h-3 w-3":
                            property[0] === "is_active" &&
                            (!value || value === 0),
                        },
                        {
                          "w-[150px]": property[0] === "granted_accesses",
                        }
                      )}
                      onClick={() => handleSelect(row)}
                      onDoubleClick={() =>
                        openModal({ view: "ALERT", payload: value })
                      }
                    >
                      {property[0] !== "is_active" && value}
                    </div>
                  ),
                };
              });
            return {
              ...selectedDetail,
              columns,
            };
          })
        : [],
    onwerShops = shops.info?.HLL ?? [];

  //searching
  const handleSearch = (searchTerm) => {
    searcher.findRelated({
      keyIndex: pageSettings.schema.searchIndex,
      value: searchTerm,
      targets: pageSettings.schema.searchColumns,
    });
  };

  // permission control
  const handlePermission = () => {
    let inputValue = SubmitterPermission(
        pageSettings,
        permissionFormValues
      ) as any,
      payload = {
        abilities: inputValue?.abilities,
        applications: explored?.currentItemOriginalDetails?.applications,
        email: explored?.currentItemOriginalDetails?.email,
        id: explored?.currentItemOriginalDetails?.id,
        is_active: explored?.currentItemOriginalDetails?.is_active,
        name: explored?.currentItemOriginalDetails?.name,
        phone: explored?.currentItemOriginalDetails?.phone,
      };

    setNotifier(!notifier);
    information.userUpdate(payload, token);

    setTimeout(() => {
      window.location.reload();
    }, 1500);
  };

  // acitvation and deactivation
  const handleActivation = () => {
    let inputValue = SubmitterPermission("express", permissionFormValues),
      payload = {
        ...inputValue,
        id: explored?.currentItemOriginalDetails?.id,
      };
    setNotifier(!notifier);
    information.userBlackListing(payload, token);

    setTimeout(() => {
      window.location.reload();
    }, 1500);
  };

  const handleUserRolesAssignment = (item: any) => {
    information.userAssignedRoles(item, token);

    setTimeout(() => {
      window.location.reload();
    }, 1500);
  };

  if (information.extension?.loading) {
    return <Loader />;
  }

  if (information.extension?.error) {
    return <ErrorPage />;
  }

  return (
    <div className="mt-5 md:mt-0 w-5/6">
      {/* tag */}
      <TagShortDecription
        title="Administrative"
        description="This is section is only visible to administrators"
        outerlayerStyle="md:col-span-1"
        innerBodyStyle="px-4 sm:px-0 my-10"
        innerHeaderStyle="text-lg font-medium leading-6 text-gray-900"
        innerlayerStyle="mt-1 text-sm text-gray-600"
      />

      {/* ssearch */}
      <div className="bg-white my-5 p-3 lg:p-5 md:p-10 focus:ring-indigo-500 shadow">
        <SearchBar searching={(v) => handleSearch(v)} />
        <ControlButton
          onClick={() => explore({ isOpen: !explored.isOpen, editor: 2 })}
          toggleCondition={explored.isOpen}
          authenticity={pageSettings.item.counter && isAdmin}
          totalCount={information?.extension?.info?.HLL?.total}
        />
      </div>

      {/* edit section */}
      {explored.isOpen && (
        <div className=" border border-white shadow">
          <ActionButton
            onClickEditor={() => explore({ isOpen: true, editor: 2 })}
            onClickControl={() =>
              explore({ ...explored, isOpen: true, editor: 2 })
            }
            styliCondition={explored.editor}
            settings={{
              control: true,
              editor: false,
            }}
          />

          <div>
            {explored.editor === 2 && (
              <>
                <PermissionForm
                  schema={formSchema}
                  item={explored.item}
                  schemaSelect={pageSettings.schema.option}
                  submit={(e: SyntheticEvent) => {
                    e.preventDefault();
                    handlePermission();
                  }}
                  onChange={(v) =>
                    setpermissionFormValues({
                      ...permissionFormValues,
                      ability: v?.title,
                    })
                  }
                  clearButtonTitle="Deactivate / Activate"
                  onClear={(e: SyntheticEvent) => {
                    e.preventDefault();
                    handleActivation();
                  }}
                  setClearButtonTitle={true}
                  clearButtonStyli="bg-red-300 hover:bg-red-400"
                  colorization={false}
                />

                <GrantAccess
                  // @ts-ignore
                  grantedAccessz={
                    explored?.currentItemOriginalDetails?.granted_accesses
                  }
                  assignRole={handleUserRolesAssignment}
                  // @ts-ignore
                  userID={explored?.currentItemOriginalDetails?.id}
                />
              </>
            )}
          </div>
        </div>
      )}

      {/* list */}
      <TableLayout
        list={listed}
        pagination={{
          from: information?.extension?.info?.HLL?.from,
          to: information?.extension?.info?.HLL?.to,
          total: information?.extension?.info?.HLL?.total,
          current: information?.extension?.info?.HLL?.current_page,
          last: information?.extension?.info?.HLL?.last_page,
          goto: (pageNumber: number) =>
            information.getUser({ page: pageNumber, permission: 3 }),
        }}
      />
    </div>
  );
}
export default Users;

function filterInformation(info: any) {
  let control = JSON.parse(info?.abilities ?? "[]")?.reduce(
      (p: number, c: number) => p + c,
      0
    ),
    selectedDetails = {
      is_active: info?.is_active,
      ID: info?.id,
      name: info?.name,
      email: info?.email,
      phone: info?.phone,
      shops: info?.shops?.map((v, i) => i + 1 + ")." + v.name).join(",\n"),
      role: control > 3 ? "Admin" : control === 3 ? "Seller" : "Buyer",
      granted_accesses: info?.granted_accesses,
    };
  return selectedDetails;
}

interface GrantAccessProps {
  grantedAccessz: string[];
  assignRole: any;
  userID: number;
}
interface ToggleProps {
  status: boolean;
  index: number | null;
}
interface HandleSelectionProps {
  status: boolean;
  index: number | null;
  access: string;
}
function GrantAccess({ grantedAccessz, assignRole, userID }: GrantAccessProps) {
  const [submissionSignal, setsubmissionSignal] =
      React.useState<boolean>(false),
    [toggle, setToggle] = React.useState<ToggleProps>({
      status: false,
      index: null,
    }),
    // @ts-ignore
    parseValues = JSON.parse(grantedAccessz ?? "[]") ?? [],
    [granted, setgranted] = React.useState<string[]>(parseValues),
    checkAccess = (current: string) => {
      let status = granted.find(
        (access) => access.toLowerCase() === current.toLowerCase()
      );
      return Boolean(status);
    },
    handleSelection = ({ status, index, access }: HandleSelectionProps) => {
      let checkIfExist = Boolean(
        granted.find((value) => value.toLowerCase() === access.toLowerCase())
      );

      if (checkIfExist) {
        return setgranted(
          granted.filter(
            (value) => value.toLowerCase() !== access.toLowerCase()
          )
        );
      } else {
        setToggle({ status, index });
        return setgranted([...granted, access]);
      }
    };
  return (
    <div className={styles.constainerWrapper}>
      <div
        onClick={() => {
          setsubmissionSignal(!submissionSignal);
          assignRole({ id: userID, granted_accesses: granted });
        }}
        className={submissionSignal ? styles.buttonConfirm : styles.button}
      >
        <div className={styles.buttonText}>{"Assign Roles"}</div>
      </div>
      {accessControlAdmin
        .filter(
          (v) =>
            !v.token.toLowerCase().includes("my") &&
            !v.token.toLowerCase().includes("dashboard")
        )
        .map((section, index) => (
          <div className={styles.toggleConstainer} key={index}>
            <div className={styles.text}>{section.label}</div>
            <div
              className={classNames(
                styles.toggleButton,
                checkAccess(section.token) ||
                  (toggle.index === index && toggle.status)
                  ? styles.toggleButtonActiveBackground
                  : styles.toggleButtonInactiveBackground
              )}
            >
              <div
                className={classNames(styles.toggleButtonBall)}
                onClick={() =>
                  handleSelection({
                    status: !toggle.status,
                    index,
                    access: section.token,
                  })
                }
              ></div>
            </div>
          </div>
        ))}
      <div className={styles.spacer}></div>
    </div>
  );
}

const styles = {
  constainerWrapper:
    "m-[10px] bg-[white] overflow-scroll border-[2px] border-[#f0f0f0] rounded-[10px] h-[300px] p-[10px]",
  spacer: "p-[10px]",
  text: "text-[16px] capitalize w-fit",
  toggleConstainer:
    "border-[1px] border-[#f0f0f0] flex justify-between items-center m-1 px-5",
  toggleButton:
    "border-[5px] rounded-[20px] border-[lightgrey] bg-[lightgrey] w-[100px] h-[30px] my-[5px]",
  toggleButtonInactiveBackground: "bg-[grey] flex justify-start",
  toggleButtonActiveBackground: "bg-[blue] flex justify-end",
  toggleButtonBall:
    "border-[1px] rounded-[50%] border-[grey] bg-[#ffffff] w-[25px] h-[25px] mt-[-2px]",
  button:
    "mb-[10px] w-[200px] flex justify-center items-end bg-[blue] rounded-[10px] cursor-pointer",
  buttonConfirm:
    "mb-[10px] w-[200px] flex justify-center items-end bg-[green] rounded-[10px] cursor-pointer",
  buttonText: "text-center p-[1px] text-[18px] text-[#ffffff] font-bold",
};
