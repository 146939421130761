import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { templateAction, initialState, templateSelector } from "../../redux/reducers/template-reducer";

type UseTemplatesProps = {
    createOrUpdateTemplates:(payload:any) => any 
    getTemplates:(payload:any) => any 
    deactivateTemplates:(payload:any) => any 
    deleteTemplates:(payload:any) => any

} & typeof initialState

export const useTemplates = ():UseTemplatesProps => {
    const dispatch = useDispatch();
    const state = useSelector(templateSelector);


    const createOrUpdateTemplates = (payload:any) => dispatch<any>(templateAction({path:"/templates", payload}));
    const getTemplates = (payload:any) => dispatch<any>(templateAction({path:"/templatess", payload}));
    const deactivateTemplates = (payload:any) => dispatch<any>(templateAction({path:"/templates/status", payload}));
    const deleteTemplates = (payload:any) => dispatch<any>(templateAction({path:"/templates/status", payload,}));

    return {
        ...state,
        createOrUpdateTemplates, 
        getTemplates, 
        deactivateTemplates, 
        deleteTemplates
    }
}