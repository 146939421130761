import React, { createContext, Dispatch, useContext, useReducer } from "react";

const initialState = {
    view: "",
    payload:null,
    isOpen:false
}

const StateContext = createContext<typeof initialState>(initialState);
StateContext.displayName = "StateContext";
const DispatchContext = createContext<Dispatch<ActionProps> | undefined>(undefined);
DispatchContext.displayName = "DispatchContext";

export function ModalProvider ({ children }:any) {
    const [state, dispatch] = useReducer(modalReducer, initialState);
    return (
        <StateContext.Provider value={state}>
            <DispatchContext.Provider value={dispatch}> 
                {children}
            </DispatchContext.Provider>
        </StateContext.Provider>
    )
}

export function useModal() {
    const state = useContext(StateContext);
    const dispatch = useContext(DispatchContext);

    if (state === undefined) {
        throw new Error("use State Modal must be under Provider");
    }

    if (dispatch === undefined) {
        throw new Error("use Dispatch Modal must be under Provider");
    }

    return {
        ...state,
        openModal: ({ view, payload }) => {
            dispatch ({ type:"OPEN", view,  payload });
        },
        closeModal: () => {
            dispatch ({ type:"CLOSE",});
        }
    }
}

enum ViewOptionProps { 
    ALERT="ALERT",
}
type ActionProps = {
    type: "OPEN" | "CLOSE" | null
    view?: ViewOptionProps | any
    payload?:any
}

function modalReducer(state = initialState, action: ActionProps) {
    switch (action.type) {
        case "OPEN":
            return{
                ...state,
                view:action.view,
                payload:action.payload,
                isOpen:true
            };
        case "CLOSE":
            return{
                ...state,
                view:undefined,
                payload:null,
                isOpen:false
            };   
        default:
            throw new Error("Unknown Modal Action!");
    }
}
