import React, { useEffect } from "react";
import axios from "axios";

import { useChat } from "../../components/hook/use-chat";
import { baseURl } from "../../redux/env/host-url";
import { ErrorPage } from "./error";

interface chatMessageProps {
  sent: number;
  recieved: number;
  message: string;
}
interface chatsProps {
  admin_name: string;
  admin_online: boolean;
  user_ip_address: string;
  user_name: string;
  user_email: string;
  chats: chatMessageProps[];
}

function ChatLogMessages({ chats, handleChat }: any) {
  const { createOrUpdateChat, chatStatus, getUserChat } = useChat(),
    { chatLogs, fetch, currentChat, melogs } = FetchChatWitoutStateManagement(),
    [vewLogs, setLogs] = React.useState<chatsProps | null>(null),
    [counter, setcounter] = React.useState<number>(0),
    handleSendMessage = async (logs: any) => {
      let payload = {
        ...logs,
        admin_online: true,
      };

      //update user chat log
      await createOrUpdateChat(payload);
    };

  const handleOpen = async (logs: any) => {
    setLogs(logs);
    getUserChat(logs);

    // mark as read
    await chatStatus(logs);
  };

  let listed = chatLogs
    ? chatLogs?.map((value: any) => {
        return (value = {
          ...value,
          key: String(value.id),
        });
      })
    : [];

  useEffect(() => {
    fetch(1);
  }, []);

  useEffect(() => {
    currentChat({
      user_ip_address: vewLogs?.user_ip_address,
    });

    const timer = setInterval(() => {
      setcounter(() => {
        if (counter === 100) {
          return 0;
        }
        return counter + 1;
      });
    }, 10000);

    return () => {
      clearInterval(timer);
    };
  }, [counter]);

  if (chats?.error) {
    return <ErrorPage />;
  }

  // if (chats.loading) {
  //   return <Loader />;
  // }
  return (
    <div>
      {Boolean(vewLogs) ? (
        <ChatPanel
          logs={melogs ?? vewLogs}
          onSubmit={handleSendMessage}
          closeChat={() => setLogs(null)}
        />
      ) : (
        <div className={styles.container}>
          {listed.map((item: any, index: number) => (
            <ChatCard
              key={Math.random() + index}
              openChat={(logs) => handleOpen(logs)}
              item={item}
            />
          ))}
        </div>
      )}
    </div>
  );
}

interface ListedItemsProps {
  logs: any;
  onSubmit: (arg: any) => any;
  closeChat: () => any;
}
function ChatPanel({ logs, onSubmit, closeChat }: ListedItemsProps) {
  const [messageLogged, messageLog] = React.useState<chatsProps | any>(logs),
    [loadPrev, setPrev] = React.useState<number>(-6),
    [sentMessage, sendMessage] = React.useState<chatMessageProps>({
      sent: 0,
      recieved: 0,
      message: "",
    }),
    handleSendMessage = () => {
      setPrev(-6);
      let updateLog = {
        ...messageLogged,
        chats: [...messageLogged?.chats, sentMessage],
      };
      messageLog(updateLog);
      onSubmit(updateLog);

      setTimeout(() => {
        sendMessage({
          sent: 0,
          recieved: 0,
          message: "",
        });
      }, 1000);
    };

  React.useEffect(() => {
    messageLog(logs);
  });
  return (
    <div>
      <span className={styles.closeLogs} onClick={closeChat}>
        {"X"}
      </span>
      <span className={styles.prevousMessages} onClick={() => setPrev(0)}>
        {"Load previous"}
      </span>
      <div className={styles.container1}>
        {messageLogged?.chats
          ?.slice(loadPrev)
          ?.map((chat: any, index: number) => (
            <div key={index}>
              {chat.sent === 1 && (
                <div className={styles.youFrame}>
                  <div className={styles.meYouFrame}>
                    <div className={styles.you}>{chat.message}</div>
                  </div>
                </div>
              )}
              {chat.recieved === 1 && (
                <div className={styles.meFrame}>
                  <div className={styles.meYouFrame}>
                    <div className={styles.me}>{chat.message}</div>
                  </div>
                </div>
              )}
            </div>
          ))}
      </div>
      <div className={styles.send}>
        <input
          className={styles.sendInput}
          onChange={(v) =>
            sendMessage({
              sent: 0,
              recieved: 1,
              message: v.target?.value,
            })
          }
          placeholder="Type here..."
          autoCapitalize="none"
          autoFocus={true}
          autoComplete="off"
          value={sentMessage.message}
        />
        <button className={styles.sendBtn} onClick={handleSendMessage}>
          <span className={styles.sendspan}>{">"}</span>
        </button>
      </div>
    </div>
  );
}

interface ChatCardProps {
  openChat: (arg: any) => any;
  item: any;
}
function ChatCard({ item, openChat }: ChatCardProps) {
  const handleAccpetSupport = () => {
    openChat(item);
  };
  return (
    <div className={styles.card}>
      <div className={styles.cardHeader}>
        {!item?.admin_online ? (
          <div className={styles.status}>
            <span className={styles.cardspan}>Incoming</span>
            <span
              onClick={handleAccpetSupport}
              className={styles.cardspan + " " + styles.cardBtn}
            >
              Accept
            </span>
          </div>
        ) : (
          <div className={styles.status}>
            <div className={styles.limit}>
              <span
                onClick={() => openChat(item)}
                className={styles.cardspan + " " + styles.received}
              >
                Received
              </span>
              <span className={styles.cardspan + " " + styles.received}>
                {item?.admin_name}
              </span>
            </div>
            <div className={styles.limit}>
              {item?.received_time.split("-").map((value: string) => (
                <span className={styles.cardspan + " " + styles.received}>
                  {value}
                </span>
              ))}
            </div>
          </div>
        )}
      </div>
      <div className={styles.cardBody}>
        <div className={styles.cardspan + " " + styles.alignment}>
          {item?.user_name}
        </div>
        <div className={styles.cardspan + " " + styles.alignment}>
          {item?.user_email}
        </div>
      </div>
    </div>
  );
}

function FetchChatWitoutStateManagement() {
  const [chatLogs, setChats] = React.useState<chatsProps[]>([]),
    [melogs, setLogs] = React.useState<chatsProps[]>([]);

  async function fetch(page: number) {
    axios
      .post(
        `${baseURl + "/chats"}`,
        { page },
        {
          headers: {
            "Content-Type": "application/json",
            //"Authorization": `Bearer ${token}`
          },
        }
      )
      .then((result) => {
        // console.log('result', result.data);
        setChats(result.data?.HLL?.data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }
  async function currentChat(chat: any) {
    axios
      .post(
        `${baseURl + "/chat/me"}`,
        { user_ip_address: chat.user_ip_address },
        {
          headers: {
            "Content-Type": "application/json",
            //"Authorization": `Bearer ${token}`
          },
        }
      )
      .then((result) => {
        // console.log('result', result.data);
        setLogs(result.data?.detail);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }

  return {
    fetch,
    chatLogs,
    currentChat,
    melogs,
  };
}
export default ChatLogMessages;

const styles = {
  container1:
    "border-[2px] rounded-md bg-white border-[#f0f0f0] p-[10px] mx-[10px] mb-[20px] min-h-[79%] max-h-screen min-w-[310px] md:w-[500px] overflow-auto",
  container:
    "border-[2px] rounded-md bg-white border-[#f0f0f0] p-[10px] m-[10px] mb-[20px] min-h-[79%] max-h-screen overflow-auto",
  meYouFrame: "border-[#f0f0f0] border-[1px] rounded-md w-[60%] p-[2px]",
  me: "text-black bg-[#f0f0f0] p-[5px] text-[20px]",
  meFrame: "m-[5px] w-[100%] flex flex-row justify-end",
  you: "text-white bg-[green] p-[5px] text-[20px] font-normal",
  youFrame: "m-[5px] w-[100%] flex flex-row justify-start",
  send: "flex flex-row justify-center m-[10px]",
  sendInput: "w-[90%] border-[1px] border-[#f0f0f0] p-[10px] bg-white",
  sendBtn: "bg-[green] text-[blue] p-[10px] w-[10%] text-center",
  sendspan: "text-white font-bold",

  // card
  status: "flex flex-row justify-between",
  card: "bg-[#ffffff] h-[200px] px-[20px] py-[40px] my-[5px] border-[1px] border-[#f0f0f0] shadow flex flex-col justify-between",
  cardHeader: "",
  cardBody: "",
  cardspan: "text-[20px]",
  cardBtn: "font-bold text-white bg-[green] p-[10px] shadow",
  received: "font-bold text-[green]",
  alignment: "mt-[2.5px]",
  limit: "w-[50%]",
  closeLogs: "text-right font-bold ml-[20px] my-[10px] px-[20px]",
  prevousMessages: "text-center bg-white",
};
