import React from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";

import { useRemoteControl } from "../modal/toggler";

type SidebarProps = {
  list:any
}

export const Sidebar = ({ list = [] }: SidebarProps) => {
  const { closeRemoveControl } = useRemoteControl();
  const [hightlighted, hightlight] = React.useState<number>(-1);
  return (
    <>
      {list.map((value, index)=>(
        <Link  
          key={index}
          to={value.href}
          onClick={()=>hightlight(index)}
          className={classNames(
            "flex w-full items-center text-base text-gray-600 text-start focus:text-accent py-2",
            {"text-blue-600": hightlighted === index}
          )}
        >
          {/* <img
            className="block h-6 w-6"
            src={value.icon}
            alt="icon"
          /> */}
          <span onClick={() => closeRemoveControl()} className="capitalize">{value.label}</span>
        </Link>
      ))}
    </>
  );
};