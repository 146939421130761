import React, { SyntheticEvent } from "react";
import { LockClosedIcon } from "@heroicons/react/solid";

import { InputBox } from "../ui/input-box";
import ROUTES from "../../constants/routes-list";
import { useUser } from "../hook/use-user";
import { useModal } from "../ui/modal/modal.context";

function Login() {
  const { openModal } = useModal();
  const { userAccess, info, error } = useUser();
  const [show, setShow] = React.useState<boolean>(false);

  const handleSubmitFrom = (e: SyntheticEvent) => {
    e.preventDefault();

    const target = e.target as typeof e.target & {
      credential: {
        value: string;
      };
      password: {
        value: string;
      };
    };
    const save = {
      credential: target.credential.value,
      password: target.password.value,
    };

    return userAccess({
      ...save,
      source: "inbuilt",
      actionType: "login",
    });
  };

  React.useEffect(() => {
    setTimeout(() => {
      if (!info.status && (info.query || error)) {
        openModal({ view: "ALERT", payload: info.query || error });
      }

      if (info.status) {
        window.location.replace("/");
      }
    }, 1200);
  }, [info]);

  return (
    <div className="min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8 my-auto">
        <div>
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            Sign in to your account
          </h2>
        </div>
        <form className="mt-8 space-y-6" onSubmit={handleSubmitFrom}>
          <div className="rounded-md shadow-sm -space-y-px">
            <InputBox
              type="text"
              autoComplete="credential"
              required={true}
              classNameInput="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
              placeholder="Email or Phone Number e.g +44xxx"
              name="credential" //{...register('email')}
            />
            <InputBox
              type={show ? "texe" : "password"}
              autoComplete="current-password"
              required={true}
              classNameInput="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
              placeholder="Password"
              name="password"
            />
          </div>

          <div className="flex items-center">
            <input
              type="checkbox"
              name="show_password"
              onClick={() => setShow(!show)}
            />
            <div className="ml-3 text-[12px]">Show Password</div>
          </div>

          <div className="flex items-center justify-between">
            <div className="text-sm">
              <a
                href={ROUTES.RESET}
                className="font-medium text-indigo-600 hover:text-indigo-500"
              >
                Forgot your password?
              </a>
            </div>
            <div className="text-sm">
              <a
                href={ROUTES.REGISTER}
                className="font-medium text-indigo-600 hover:text-indigo-500"
              >
                I don't have an account
              </a>
            </div>
          </div>

          <div>
            <button
              type="submit"
              className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-[#403d93] hover:bg-[#6e6bd5] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                <LockClosedIcon
                  className="h-5 w-5 text-indigo-500 group-hover:text-indigo-400"
                  aria-hidden="true"
                />
              </span>
              Sign in
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Login;
