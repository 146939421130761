export const formSchema = [

    // shop schema
    { key: "name", value: "Name", placeholderTexT:"", attach:0, required:true, schema:"shopForm" , display:true  },
    { key: "description", value: "Description", placeholderTexT:"", attach:0, required:true, schema:"shopForm" , display:true  },
    { key: "street_name", value: "Street Name", placeholderTexT:"", attach:0, required:true, schema:"shopForm" , display:true  },
    // { key: "city", value: "City", placeholderTexT:"", attach:0, required:true, schema:"shopForm" , display:true  },
    // { key: "state", value: "State", placeholderTexT:"", attach:0, required:true, schema:"shopForm" , display:true  },
    { key: "country", value: "Country", placeholderTexT:"", attach:0, required:true, schema:"shopForm" , display:true  },
    // _____o
    { key: "category", value: "Category", placeholderTexT:"", attach:0, required:true, schema:"shopForm" , display:true  },
    // ______c
    { key: "zip", value: "Zip / Post Code", placeholderTexT:"", attach:0, required:true, schema:"shopForm" , display:true  },
    { key: "bank_name", value: "Bank name", placeholderTexT:"", attach:0, required:true, schema:"shopForm" , display:true  },
    { key: "bank_country", value: "Bank Country", placeholderTexT:"", attach:0, required:true, schema:"shopForm" , display:true  },
    { key: "swift_code", value: "Swift Code", placeholderTexT:"", attach:0, required:false, schema:"shopForm" , display:true  },
    { key: "sort_code", value: "Sort Code", placeholderTexT:"", attach:0, required:false, schema:"shopForm" , display:true  },
    { key: "account_name", value: "Account Name", placeholderTexT:"", attach:0, required:true, schema:"shopForm" , display:true  },
    { key: "account_number", value: "Account Number", placeholderTexT:"", attach:0, required:true, schema:"shopForm" , display:true  },
    { key: "paypal_email", value: "Paypal Username", placeholderTexT:"", attach:0, required:false, schema:"shopForm" , display:true  },
    // ______oc
    { key: "is_active", value: "Status", placeholderTexT:"", attach:0, required:true, schema:"shopForm" , display:true  },


    { key: "is_active", value: "Status", options: ["Active", "Deactivate"], schema:"shopOptionForm" , display:true  },

    // product schema
    { key: 'image', value: "Image", placeholderTexT:"", attach:1, schema:"productForm" , display:true  },
    { key: 'name', value: "Name", placeholderTexT:"", attach:0, required:true, schema:"productForm" , display:true  },
    { key: 'description', value: "Description", placeholderTexT:"", attach:0, required:true, schema:"productForm" , display:true  },
    { key: 'currency', value: "Currency", placeholderTexT:"", attach:0, required:true, schema:"productForm" , display:true  },
    { key: 'price', value: "(Cancel) Price", placeholderTexT:"e.g -₦6θθ- or -₦6θθ.4θ- or Zero only", attach:0, required:true, schema:"productForm" , display:true  },
    { key: 'sale_price', value: "Sale Price", placeholderTexT:"e.g 4999 or 4999.39, (less than \'Price\' when greater than Zero)", attach:0, required:true, schema:"productForm" , display:true  },
    // { key: 'weight', value: "Weight", placeholderTexT:"", attach:0, required:true, schema:"productForm" , display:true  },
    { key: 'in_stock', value: "in_stock", placeholderTexT:"e.g 60", attach:0, required:true, schema:"productForm" , display:true  },
    { key: 'unit', value: "Unit", placeholderTexT:"e.g cm", attach:0, required:false, schema:"productForm" , display:true  },
    { key: 'height', value: "Height", placeholderTexT:"e.g 4", attach:0, required:false, schema:"productForm" , display:true  },
    { key: 'width', value: "Width", placeholderTexT:"e.g 4", attach:0, required:false, schema:"productForm" , display:true  },
    { key: 'length', value: "Length", placeholderTexT:"e.g 4", attach:0, required:false, schema:"productForm" , display:true  },
    { key: 'color', value: "Colour", placeholderTexT:"", attach:0, required:false, schema:"productForm" , display:true  },
    { key: 'size', value: "Size", placeholderTexT:"", attach:0, required:false, schema:"productForm" , display:true  },
    { key: 'brand_name', value: "Brand", placeholderTexT:"", attach:0, required:true, schema:"productForm" , display:true  },
    // _____o
    { key: "category", value: "Category", placeholderTexT:"", attach:0, required:true, schema:"productForm" , display:true  },
    { key: "subcategory", value: "Subcategory", placeholderTexT:"", attach:0, required:true, schema:"productForm" , display:true  },
    { key: "shop", value: "shop", placeholderTexT:"", attach:0, required:true, schema:"productForm" , display:true  },
    // ______c


    { key: 'premier', value: "Premier", options: ["yes","no"], schema:"productOptionForm" , display:true  },
    { key: 'product_type', value: "Status", options: ["new","pre-owned"], schema:"productOptionForm" , display:true  },
    { key: "state", value: "State", options: ["active","draft"], schema:"productOptionForm" , display:true  },
    // { key: 'is_active', value: "Status", options: ["Active", "Deactivate"], schema:"productOptionForm" , display:true  },


    { key: "name", value: "Name", placeholderTexT:"", attach:0, required:true, schema:"brandForm" , display:true  },
     // _____o
    { key: "typeTag", value: "typeTag", placeholderTexT:"", attach:0, required:true, schema:"brandForm" , display:true  },
    // ______c

    { key: "is_active", value: "Status", options: ["Active", "Deactivate"], schema:"brandOptionForm" , display:true  },


    // _____o
    { key: "category", value: "Category", placeholderTexT:"", attach:0, required:true, schema:"categoryForm" , display:true  },
    { key: "subcategory", value: "Subcategory", placeholderTexT:"", attach:0, required:true, schema:"categoryForm" , display:true  },
    { key: "currency", value: "currency", placeholderTexT:"", attach:0, required:true, schema:"categoryForm" , display:true  },
    // ______c
    // { key: "name", value: "New Category Name", placeholderTexT:"", attach:0, required:true, schema:"categoryForm" , display:true  },
    // { key: "subcategory_name", value: "New Subcategory Name", placeholderTexT:"", attach:0, required:true, schema:"categoryForm" , display:true  },
    // { key: "commission_currency", value: "Currency", placeholderTexT:"Fill-in for new category", attach:0, required:true, schema:"categoryForm" , display:true  },
    { key: "commission_flat_rate", value: "commission(%)", placeholderTexT:"Fill-in for new category", attach:0, required:true, schema:"categoryForm" , display:true  },
    { key: "commission_monthly", value: "commission(Amount)", placeholderTexT:"Fill-in for new category", attach:0, required:false, schema:"categoryForm" , display:true  },
    // { key: "serial", value: "Subcategory Pin Number", placeholderTexT:"", attach:0, required:true, schema:"categoryForm" , display:true  },


    { key: "value_per_dollar", value: "Value Per Dollar", placeholderTexT:"", attach:0, required:true, schema:"exchangesForm" , display:true  },
    { key: "currency_symbol", value: "Currency Symbol", placeholderTexT:"", attach:0, required:true, schema:"exchangesForm" , display:true  },
    { key: "code", value: "Currency Code", placeholderTexT:"", attach:0, required:true, schema:"exchangesForm" , display:true  },
    { key: "currency", value: "Currency name", placeholderTexT:"", attach:0, required:true, schema:"exchangesForm" , display:true  },
    { key: "country", value: "Country", placeholderTexT:"", attach:0, required:true, schema:"exchangesForm" , display:true  },


    { key: "from_email", value: "From Email", placeholderTexT:"", attach:0, required:true, schema:"templatesForm" , display:true  },
    { key: "subject", value: "Subject", placeholderTexT:"", attach:0, required:true, schema:"templatesForm" , display:true  },
    // { key: "dispatch_status", value: "Dispatch Status", placeholderTexT:"", attach:0, required:true, schema:"templatesForm" , display:true  },
    { key: "message", value: "Message", placeholderTexT:"", attach:0, required:true, schema:"templatesForm" , display:true  },
    // { key: "serial", value: "Serial", placeholderTexT:"", attach:0, required:true, schema:"templatesForm" , display:true  },


    { key: "is_active", value: "Status", options: ["Active", "Deactivate"], schema:"templatesOptionForm" , display:true  },



    { key: "currency", value: "Currency", placeholderTexT:"", attach:0, required:true, schema:"mileForm" , display:true  },
    { key: "country", value: "Country", placeholderTexT:"", attach:0, required:true, schema:"mileForm" , display:true  }, 
    { key: "value_per_mile", value:"Value per Mile (USD)", placeholderTexT:"", attach:0, required:true, schema:"mileForm" , display:true  },


    { key: "tracking_number", value: "tracking_number", placeholderTexT:"", attach:0, required:true, schema:"orderForm" , display:true  },
    { key: "customer_contact", value: "Phone Number", placeholderTexT:"", attach:0, required:true, schema:"orderForm" , display:true  },
    { key: "status", value: "Status", placeholderTexT:"", attach:0, required:true, schema:"orderForm" , display:true  },
    { key: "amount", value: "Amount", placeholderTexT:"", attach:0, required:true, schema:"orderForm" , display:true  },
    { key: "sales_tax", value: "sales_tax", placeholderTexT:"", attach:0, required:true, schema:"orderForm" , display:true  },
    { key: "paid_total", value: "Paid total", placeholderTexT:"", attach:0, required:true, schema:"orderForm" , display:true  },
    { key: "total", value: "Total", placeholderTexT:"", attach:0, required:true, schema:"orderForm" , display:true  },
    { key: "bank_name", value: "Bank name", placeholderTexT:"", attach:0, required:true, schema:"orderForm" , display:true  },
    { key: "discount", value: "Discount", placeholderTexT:"", attach:0, required:true, schema:"orderForm" , display:true  },
    { key: "payment_gateway", value: "Payment Method", placeholderTexT:"", attach:0, required:true, schema:"orderForm" , display:true  },
    { key: "shipping_address", value: "Shipping Address", placeholderTexT:"", attach:0, required:true, schema:"orderForm" , display:true  },
    { key: "billing_address", value: "Billing Address", placeholderTexT:"", attach:0, required:true, schema:"orderForm" , display:true  },
    { key: "logistics", value: "Logistics", placeholderTexT:"", attach:0, required:true, schema:"orderForm" , display:true  },
    { key: "delivery_fee", value: "Delivery Fee", placeholderTexT:"", attach:0, required:true, schema:"orderForm" , display:true  },


    { key: 'dispatch_status', value: "Delivery Status",options:[
        "New Order",
        "Order Confirmed",
        "Order Shipped",
        "Order in Transit",
        "Order Delivered",
        "Order Cancelled",
    ], schema:"orderOptionForm" , display:true  },


    { key: "shop", value: "Shop", placeholderTexT:"", attach:0, required:true, schema:"subscriptionForm" , display:true  },
    { key: "type", value: "Type", placeholderTexT:"", attach:0, required:true, schema:"subscriptionForm" , display:true  },
    { key: "nature", value: "Nature", placeholderTexT:"", attach:0, required:true, schema:"subscriptionForm" , display:true  },
    { key: "period", value: "Period", placeholderTexT:"", attach:0, required:true, schema:"subscriptionForm" , display:true  },
    { key: "unit", value: "Unit", placeholderTexT:"", attach:0, required:true, schema:"subscriptionForm" , display:true  },
    { key: "total_clicks", value: "Total Clicks", placeholderTexT:"", attach:0, required:true, schema:"subscriptionForm" , display:true  },
    { key: "amount", value: "Amount", placeholderTexT:"", attach:0, required:true, schema:"subscriptionForm" , display:true  },
    { key: "start_date", value: "Start Date", placeholderTexT:"", attach:0, required:true, schema:"subscriptionForm" , display:true  },
    { key: "end_date", value: "End Date", placeholderTexT:"", attach:0, required:true, schema:"subscriptionForm" , display:true  },


    { key: "is_active", value: "Status", options: ["Active", "Deactivate"], schema:"subscriptionOptionForm" , display:true  },


    { key: "duration", value: "Duration", placeholderTexT:"", attach:0, required:true, schema:"shippingForm" , display:true  },
    { key: "currency", value: "Currency", placeholderTexT:"", attach:0, required:true, schema:"shippingForm" , display:true  },
    { key: "amount", value: "Amount", placeholderTexT:"", attach:0, required:true, schema:"shippingForm" , display:true  },
    { key: "means", value: "Means", placeholderTexT:"", attach:0, required:true, schema:"shippingForm" , display:true  },
    { key: "note", value: "Note", placeholderTexT:"", attach:0, required:false, schema:"shippingForm" , display:true  },


    { key: "is_active", value: "Status", options: ["Active", "Deactivate"], schema:"shippingOptionForm" , display:true  },

    { key: "title", value: "Title", placeholderTexT:"", attach:0, required:true, schema:"jobForm" , display:true  },
    { key: "description", value: "Description", placeholderTexT:"", attach:0, required:true, schema:"jobForm" , display:true  },
    { key: "qualification", value: "Qualification", placeholderTexT:"", attach:0, required:true, schema:"jobForm" , display:true  },
    { key: "details", value: "Detail", placeholderTexT:"", attach:0, required:true, schema:"jobForm" , display:true  },
    { key: "expiry", value: "Before", placeholderTexT:"dd/mm/yyyy", attach:0, required:true, schema:"jobForm" , display:true  },
    // { key: "state", value: "State", placeholderTexT:"", attach:0, required:true, schema:"jobForm" , display:true  },
    { key: "country", value: "Country", placeholderTexT:"", attach:0, required:true, schema:"jobForm" , display:true  },


    // { key: "type", value: "Type", placeholderTexT:"", attach:0, required:true, schema:"subscriptionPlanForm" , display:true  },
    // { key: "nature", value: "Nature", placeholderTexT:"", attach:0, required:true, schema:"subscriptionPlanForm" , display:true  },
    // { key: "period", value: "Period", placeholderTexT:"", attach:0, required:true, schema:"subscriptionPlanForm" , display:true  },
    { key: "total_clicks", value: "Total clicks", placeholderTexT:"", attach:0, required:false, schema:"subscriptionPlanForm" , display:true  },
    { key: "amount", value: "Amount", placeholderTexT:"", attach:0, required:true, schema:"subscriptionPlanForm" , display:true  },
    // { key: "unit", value: "Unit", placeholderTexT:"", attach:0, required:true, schema:"subscriptionPlanForm" , display:true  },
    { key: "currency", value: "Currency", placeholderTexT:"", attach:0, required:true, schema:"subscriptionPlanForm" , display:true  },


    { key: "is_active", value: "Status", options: ["Active", "Deactivate"], schema:"subscriptionPlanOptionForm" , display:true  },


    { key: "title", value: "Title", placeholderTexT:"", attach:0, required:true, schema:"supportForm" , display:true  },
    { key: "message", value: "Message", placeholderTexT:"", attach:0, required:true, schema:"supportForm" , display:true  },
    { key: "country", value: "Country", placeholderTexT:"", attach:0, required:true, schema:"supportForm" , display:true  },


    { key: "is_active", value: "Status", options: ["Active", "Deactivate"], schema:"supportOptionForm" , display:true  },


    { key: "is_active", value: "Status", options: ["Active", "Deactivate"], schema:"orderStatusOptionForm" , display:true  },


    { key: "name", value: "Title", placeholderTexT:"", attach:0, required:true, schema:"orderStatusForm", display:true  },



    { key: "bank_name", value: "Bank Name", placeholderTexT:"", attach:0, required:true, schema:"bankForm" , display:true  },
    { key: "bank_country", value: "Bank Country", placeholderTexT:"", attach:0, required:true, schema:"bankForm" , display:true  },
    { key: "swift_code", value: "Swift Code", placeholderTexT:"", attach:0, required:true, schema:"bankForm" , display:true  },
    { key: "sort_code", value: "Sort Code", placeholderTexT:"", attach:0, required:true, schema:"bankForm" , display:true  },
    { key: "account_name", value: "Account Name", placeholderTexT:"", attach:0, required:true, schema:"bankForm" , display:true  },  
    { key: "account_number", value: "Account Number", placeholderTexT:"", attach:0, required:true, schema:"bankForm" , display:true  },  


    { key: "paypal_email", value: "Paypal Email", attach:0, required:true, schema:"paypalForm", display:true  }, 
    { key: "name_of_seller", value: "Name", attach:0, required:true, schema:"paypalForm", display:true  }, 

    { key: "name", value: "Name", schema:"userForm" , display:true  },
    { key: "email", value: "Email", schema:"userForm" , display:true  },
    { key: "phone", value: "Phone Number", schema:"userForm" , display:true  },

    // { key: "is_active", value: "Status", options: ["Active", "Deactivate"], schema:"userOptionForm" , display:true  },
    { key: "abilities", value: "Permission", options: ["Admin", "Non-Admin"], schema:"userOptionForm" , display:true  },

    { key: "street_name", value: "Street Address", schema:"userForm",  display:true  },
    { key: "city", value: "City", schema:"userForm",  display:true  },
    { key: "state", value: "State  / Province", schema:"userForm",  display:true  },
    { key: "country", value: "Country", schema:"userForm",  display:true  },
    { key: "zip", value: "Zip / Post Code", schema: "userForm", display: true },


    { key: "location_A", value: "Location A", placeholderTexT:"Country,State,Area", attach:0, required:true, schema:"customDistancesForm", display:true },
    { key: "location_B", value: "Location B", placeholderTexT:"Country,State,Area", attach:0, required:true, schema:"customDistancesForm", display:true },
    { key: "distance_km", value: "Distance (Kilometer)", placeholderTexT:"536", attach:0, required:true, schema:"customDistancesForm", display:true },

    
    { key: "deletion_status", value: "Deletion Status", placeholderTexT: "", attach: 0, required: true, schema: "deletionRequestForm", display: true },
    { key: "customer", value: "Customer", placeholderTexT:"", attach:0, required:true, schema:"deletionRequestForm", display:true },
    { key: "feedback", value: "Feedback", placeholderTexT:"", attach:0, required:true, schema:"deletionRequestForm", display:true },
    { key: "customer_id", value: "Customer ID", placeholderTexT:"", attach:0, required:true, schema:"deletionRequestForm", display:true },
    { key: "is_active", value: "Status", placeholderTexT:"", attach:0, required:true, schema:"deletionRequestForm", display:true },

];

