import React, { createContext, Dispatch, useContext, useReducer } from "react"

enum ViewOptionProps {
    SIDEBAR="SIDEBAR"
}
type ActionProps = {
    type: string,
    view?: ViewOptionProps | any,
    payload?:any,
}
const initialState = {
    view: "",
    payload:null,
    isOpen: false
}

function reducer(state = initialState, action:ActionProps) {
    switch (action.type) {
        case "open":
            return {
                ...state,
                payload:action.payload,
                view:action.view,
                isOpen:true
            };

        case "close":
            return {
                ...state,
                payload:null,
                view:"",
                isOpen:false
            };
    
        default:
            return state;
    }
}
const CreateStateContext = createContext<typeof initialState>(initialState);
CreateStateContext.displayName = "StateContext";
const CreateDispatchContext = createContext<Dispatch<ActionProps> | undefined>(undefined);
CreateDispatchContext.displayName = "DispatchContext";

export function RemoteControlProvider({children}:any){
    const [state, dispatch] = useReducer(reducer, initialState);
    return (
        <CreateStateContext.Provider value={state}>
            <CreateDispatchContext.Provider value={dispatch}>
                {children}
            </CreateDispatchContext.Provider>
        </CreateStateContext.Provider>
    )
}

export function useRemoteControl(){
    const state = useContext(CreateStateContext);
    const dispatch = useContext(CreateDispatchContext);

    if (state === undefined) {
        throw new Error("Remote Control should be under a Provider");
    }
    if (dispatch === undefined) {
        throw new Error("Remote Control should be under a Provider");
    }
    return{
        ...state,
        openRemoveControl({ payload = true, view }){
            dispatch({ type:"open", payload, view });
        },
        closeRemoveControl(){
            dispatch({ type:"close", payload:false });
        }
    }
}
