import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { 
    initialState, 
    searchEngineAcion, 
    searchSelector,
    clearSearch 
} from "../../redux/reducers/search-engine-reducer";

type UseBrandProps = {
    findRelated:(payload:any) => any 
    clearSearch:() => any 

} & typeof initialState

export const useSearchEngine = ():UseBrandProps => {
    const dispatch = useDispatch();
    const state = useSelector(searchSelector);


    const findRelated = (payload:any) => dispatch<any>(searchEngineAcion({path:"/search-related", payload}));

    return {
        ...state,
        clearSearch,
        findRelated,
    }
}

export const useSearchEngineFull = ():UseBrandProps => {
    const dispatch = useDispatch();
    const state = useSelector(searchSelector);


    const findRelated = (payload:any) => dispatch<any>(searchEngineAcion({path:"/search-dive", payload}));

    return {
        ...state,
        clearSearch,
        findRelated,
    }
}